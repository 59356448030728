import { useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import routesAuthentication from "./routesAuthentication";
import OwnNotFound from "../components/common/OwnNotFound";
import NavbarRoutes from "./NavbarRoutes";
import OwnLayout from "../components/layout/OwnLayout";
import Home from "../pages/Home/Home";
import { getStoredToken } from "../services/user-storage";
import { addPushToken, getNotificationsList } from "../network/auth";
import { queryKeys } from "../services/react-query/queryKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { onMessageListener, requestForToken } from "../firebase";

function RoutesWrapper() {
  const currentUser = useSelector((s) => s?.user?.data);
  const client = useQueryClient();

    useEffect(() => {
      if(currentUser){
        handelAddNotification();
      }
      onMessageListener((payload) => {
        console.log(payload, "wwwwwwwwwwwwwwwww");
        client.invalidateQueries([queryKeys.notificationList]);
      });
    }, [])
    
  
    const handelAddNotification = async () => {
      const userToken = await requestForToken();
      console.log("userToken", userToken);
      let body = {
        Token: userToken,
        OS: 3,
      };
      addPushToken(body).then((res) => {
        console.log("res", res);
      }).catch((err) => {
        console.log("err", err);
      })
  
    }

  const routes = useRoutes([
    {
      path: "*",
      element: <OwnNotFound />,
    },
    {
      path: "/",
      element: getStoredToken() ? <OwnLayout /> : <Navigate to="/login" replace />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        ...NavbarRoutes,
      ],
    },
    ...routesAuthentication,
  ]);
  return routes;
}

export default RoutesWrapper;
