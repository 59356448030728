import { css } from "@emotion/css";
import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Space,
} from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { IoMdNotificationsOutline } from "react-icons/io";
import React, { useState } from "react";
import SidebarMenu from "./SidebarMenu";
import img from "../../assets/images/Ellipse 16.png";
import img1 from "../../assets/images/avatar-04.png";
import img2 from "../../assets/images/avatar-05.png";
import img3 from "../../assets/images/avatar-06.png";


import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { clearStoredToken } from "../../services/user-storage";
import { setUserData } from "../../services/store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { queryKeys } from "../../services/react-query/queryKeys";
import { getNotificationsList } from "../../network/auth";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
const { Content, Sider, Header, Footer } = Layout;
function OwnLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((s) => s?.user?.data);

  const {data: notificationList} = useQuery([queryKeys.notificationList],
    () => getNotificationsList(),
    {
    enabled: !!currentUser,
  })
  console.log("notificationList", notificationList);

  const OwnLayoutStyles = css``;
  const items = [
    // {
    //   label: <p>الصفحه الشخصيه</p>,
    //   key: "0",
    // },
    // {
    //   label: <p>الاعدادات</p>,
    //   key: "1",
    // },
    // {
    //   type: "divider",
    // },
    {
      label: "تسجيل الخروج",
      danger: true,
      key: "3",
    },
  ];
  const itemsNotification = [
    {
      type:"group",
      label:"الاشعارات",
      children: notificationList?.model?.map((item) => ({
        key: item?.key,
        label: (
          <>
            <div className="notification-card">
              <div className="notification-card-info">
                <p className="notification-card-info-title">
                  {item?.title}
                  <span> {item?.description} </span>
                </p>
                <p className="notification-card-info-time">
                  {/* {item?.time} */}
                  {/* {dayjs(item?.CreationDate).fromNow()} */}
                  {/* {dayjs(item?.CreationDate).to(dayjs())} */}
                  {dayjs.utc(
                      item?.CreationDate
                    )
                    .local()
                    .format("YYYY/M/D  HH:mm")}
                </p>
              </div>
              <img src={item?.img || img1} alt="" />
            </div>
          </>
        ),
      })),
    },
    // {
    //   label: "مشاهدة الكل",
    //   key: "30",
    //   className: "view-all",
    //   onClick: () => {
    //     console.log("click");
    //   }
    // },
  ]
  return (
    <div className={OwnLayoutStyles}>
      <Layout>
        <Sider
          width={282}
          theme="light"
          breakpoint="lg"
          // collapsedWidth="0"
          collapsed={collapsed}
          collapsible
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'sticky',
            left: 0,
            top: 0,
            bottom: 0,
          }}
          onCollapse={(value) => setCollapsed(value)}
        >
          <SidebarMenu />
        </Sider>
        <Layout className="min-h-screen">
          <Header>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
              }}
            />
            <div className="flex  items-center justify-center gap-5 pr-4 pl-4 ">
              <Dropdown
                // rootClassName=""
                menu={{
                  items,
                  onClick: (e) => {
                    console.log(e);
                    if (e?.key === '3') {
                      clearStoredToken();
                      dispatch(setUserData(null));
                      navigate('/login');
                    }
                  },
                }}
                trigger={['click']}
                placement="bottom"
              >
                <Space className=" cursor-pointer gap-[10px] text-color[#002554] flex items-center justify-center m-auto">
                  {/* <DownOutlined style={{ color: "#002554" }} size={12} /> */}
                  <Badge
                    // count={5}
                    // size="small"
                    dot={true}
                    className="badge-style-own"
                  >
                    <Avatar
                      style={{ backgroundColor: '#fff', color: '#002554' }}
                      size={38}
                      // icon={<UserOutlined className="w-[38px] h-[38px] text-color-[#002554]  " />}
                    >
                      <img
                        src={img}
                        alt=""
                        className="w-[38px] h-[38px] border-2 border-solid border-white rounded-[38px]  "
                      />
                    </Avatar>
                  </Badge>
                </Space>
              </Dropdown>
              <Divider type="vertical" className="text-color[#002554] h-5 " />
              <Dropdown
                rootClassName="dropdown-style-own"
                menu={{
                  items: itemsNotification,
                  onClick: (e) => {
                    console.log(e);
                  },
                }}
                trigger={['click']}
                // placement="bottom"
              >
                <Space className=" cursor-pointer gap-[10px] text-color[#002554]  flex items-center justify-center">
                  {/* <DownOutlined style={{ color: "#002554" }} size={12} /> */}
                  <Badge
                    count={notificationList?.metas?.pagination?.totalCount}
                    overflowCount={10}
                    size="small"
                    className="badge-style"
                  >
                    <Avatar
                      size={38}
                      style={{ backgroundColor: '#fff', color: '#002554' }}
                      icon={
                        <IoMdNotificationsOutline className="w-[20px] h-[20px] text-color-[#002554] " />
                      }
                    />
                  </Badge>
                </Space>
              </Dropdown>
              {/* <Button
                type="primary"
                shape="circle"
                icon={<UserOutlined />}
                size="large"
                size="large"
              /> */}
            </div>
          </Header>
          <Content className="bg-whit m-[2.8vw] ">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default OwnLayout;
