import { Button, Form, Input, App } from "antd";
// import { useMutation } from "@tanstack/react-query";
import { authForgotPassword } from "../../../network/auth";
import { useMutation } from "@tanstack/react-query";

function ForgotPassword() {
  const { message } = App.useApp();

  const { mutate: onAuthForgotPassword, isLoading } = useMutation(
    authForgotPassword,
    {
      onSuccess: (res) => {
        console.log("ForgotPassword  res:", res);
        if (res?.code === "200") {
          message.success(res?.message);
        }
        message.error(res?.message);
      },
    }
  );

  const onFinish = (values) => {
    const body = new FormData();
    body.append("email", values?.email);
    // onAuthForgotPassword(body);
  };

  return (
    <div className="container">
      <div className="h-screen flex items-center justify-center">
        <div className="mx-auto w-[592px] max-w-full space-y-5 rounded-lg bg-white px-6 py-9 lg:px-9">
          <div className="space-y-4">
            <h1 className="text-xl font-medium">نسيت كلمة المرور</h1>
            <p className="text-lg ">
              ادخل بريدك الالكتروني لإعادة تعيين كلمة المرور
            </p>
          </div>
          <Form layout="vertical" className="form-style" onFinish={onFinish}>
            <Form.Item name="email">
              <Input placeholder="ادخل بريدك الالكتروني" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              className="mt-4"
              loading={isLoading}
            >
              إعادة تعيين كلمة المرور 
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
