import { Button, Flex, Form, Input, InputNumber, Upload, message } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import './ProductsAdd.scss';
import AddCategory from '../AddCategory/AddCategory';
import { useMutation } from '@tanstack/react-query';
import { addProducts } from '../../network/products';
import { useNavigate, useParams } from 'react-router';
import { useState } from 'react';
import {
  CheckboxCategoryAge,
  CheckboxCategoryGender,
  CheckboxCategorys,
} from '../Category/Category';
import { addCustomerProduct, addFlowers } from '../../network/flowers';
const ProductsAdd = ({ category }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { OrderNumber } = useParams();
  console.log(OrderNumber, 'OrderNumber');
  const [isImageClicked, setIsImageClicked] = useState(false);
  const [fileList, setFileList] = useState([]);
  console.log(fileList, 'fileList');
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined type="plus" className="upload-btn-wrapper-edit" />
      <div className="upload-btn">اضافة صور</div>
    </div>
  );
  // console.log(imageList, "ProductImages");
  const { mutate: onAddProducts, isLoading } = useMutation(addProducts, {
    onSuccess: (res) => {
      console.log('  res onSuccess :', res);
      if (res) {
        message.success('تم اضافة المنتج بنجاح');
        form.resetFields();
        navigate('/products');
      } else {
        message.error('حدث خطأ ما');
      }
    },
    onError: (error) => {
      console.log('error :', error);
      message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
    },
  });
  const { mutate: onAddFlowers, isLoading: loadingFlower } = useMutation(
    addFlowers,
    {
      onSuccess: (res) => {
        console.log('  res onSuccess :', res);
        if (res) {
          message.success('تم اضافة المنتج بنجاح');
          form.resetFields();
          navigate('/flowers');
        } else {
          message.error('حدث خطأ ما');
        }
      },
      onError: (error) => {
        console.log('error :', error);
        message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
      },
    }
  );
  const { mutate: onAddCustomerProduct, isLoading: loadingCustomerProduct } =
    useMutation(addCustomerProduct, {
      onSuccess: (res) => {
        console.log('  res onSuccess :', res);
        if (res) {
          message.success('تم اضافة المنتج بنجاح');
          form.resetFields();
          navigate('/requests');
        } else {
          message.error('حدث خطأ ما');
        }
      },
      onError: (error) => {
        console.log('error :', error);
        message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
      },
    });
  const onFinish = (values) => {
    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('description', values?.description);
    formData.append('price', values?.price);
    formData.append('color', values?.color);
    formData.append('size', values?.size);
    values?.ProductImages?.fileList?.forEach((item) => {
      formData.append('ProductImages', item?.originFileObj);
    });
    const combinedArray = values?.categoryProduct?.concat(
      values?.categoryGender,
      values?.categoryAge
    );
    combinedArray?.forEach((item, index) => {
      formData.append(`giftCategories[${index}].categoryId`, item);
    });
    if (category === 'flowers') {
      formData.append('productType', 1);
      onAddFlowers(formData);
    } else if (category === 'products') {
      formData.append('productType', 0);
      onAddProducts(formData);
    } else {
      // Add customer products
      console.log('Add customer products');
      formData.append('productType', 0);
      formData.append('OrderNumber', OrderNumber);
      onAddCustomerProduct(formData);
    }
  };
  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('حجم الصورة يجب ان يكون اقل من 2 ميجا بايت');
      return false;
    }
    return isLt2M;
  };

  const handleUpload = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const config = {
    rules: [
      {
        // type: 'array',
        required: true,
        message: 'يجب تحديد تصنيف المنتج',
      },
    ],
  };
  return (
    <>
      <div className="product-add">
        <div className="header-product mb-5 ">
          <div
            onClick={() => {
              if (category === 'flowers') {
                navigate('/flowers');
              } else {
                navigate('/products');
              }
            }}
          >
            <span className="header-title">
              {category === 'flowers' ? 'باقات الورد' : ' المنتجات '}
            </span>
          </div>
          <div
            className="header-path"
            // onClick={() => {
            //   form.submit();
            // }}
          >
            <span className="text-path">
              {category === 'flowers' ? 'اضافة باقة' : ' اضافة منتج '}
            </span>
          </div>
        </div>
        <Form
          name="form_item_path"
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <div className="product-add-content">
            <div className="add-images">
              <Form.Item
                name="ProductImages"
                label=""
                rules={[
                  {
                    required: true,
                    message: 'يجب اضافة صور',
                  },
                ]}
              >
                <Upload
                  // listType="picture"
                  listType="picture-card"
                  fileList={fileList}
                  // onPreview={() => setIsImageClicked(true)}
                  onChange={handleChange}
                  multiple
                  accept="image/*"
                  beforeUpload={beforeUpload}
                  customRequest={handleUpload}
                  // onDownload={false}
                  // onRemove={false}
                  // onDrop={false}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
            </div>
            <div className="add-info">
              <div className="add-info-form">
                <h2 className="add-title">تفاصيل المنتج</h2>
                <Form.Item
                  name="name"
                  label="اسم المنتج"
                  rules={[
                    {
                      required: true,
                      message: 'يجب  اضافة اسم المنتج',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="price"
                  label="سعر المنتج"
                  rules={[
                    {
                      required: true,
                      message: 'يجب  اضافة سعر المنتج',
                    },
                  ]}
                >
                  {/* <Input type="number" suffix="SR" /> */}
                  <InputNumber
                    suffix="SR"
                    style={{ width: '95%' }}
                    className="price-input"
                  />
                </Form.Item>
                <Form.Item
                  name="color"
                  label={
                    <>
                      <div className="add-label">
                        اللون <span>(اختياري)</span>
                      </div>
                    </>
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="size"
                  label={
                    <>
                      <div className="add-label">
                        المقاس <span>(اختياري)</span>
                      </div>
                    </>
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="وصف المنتج"
                  rules={[
                    {
                      required: true,
                      message: 'يجب  اضافة وصف المنتج',
                    },
                  ]}
                >
                  <TextArea
                    style={{
                      width: '100%',
                      height: '155px',
                      padding: '10px',
                      resize: 'none',
                    }}
                  />
                </Form.Item>
              </div>
              <div className="add-info-category">
                <AddCategory />
                <Form.Item
                  name="categoryGender"
                  label="الجنس"
                  className="checkbox-list-wrapper"
                  {...config}
                >
                  <CheckboxCategoryGender
                    Delete={true}
                    setValues={(value) => {
                      form.setFieldsValue({
                        categoryGender: value,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="categoryAge"
                  label="العمر"
                  className="checkbox-list-wrapper"
                  {...config}
                >
                  <CheckboxCategoryAge
                    Delete={true}
                    setValues={(value) => {
                      form.setFieldsValue({
                        categoryAge: value,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="categoryProduct"
                  label="فئة المنتج"
                  className="checkbox-list-wrapper"
                  {...config}
                >
                  <CheckboxCategorys
                    Delete={true}
                    setValues={(value) => {
                      form.setFieldsValue({
                        categoryProduct: value,
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <Flex justify="flex-end" className="submit-btn-wrapper">
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn-edit"
              loading={isLoading || loadingFlower || loadingCustomerProduct}
            >
              اضافة
            </Button>
          </Flex>
        </Form>
      </div>
    </>
  );
};

export default ProductsAdd;
