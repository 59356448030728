import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../../assets/images/logo.svg";

function BrandLogo() {
  return (
    <Link to="/">
      <img src={logo} alt="" />
    </Link>
  );
}

export default BrandLogo;
