import { Card } from 'antd';
import './DeliveryDetails.scss';
const DeliveryDetails = ({ data }) => {
  const { deliveryAddress, deliveryDate, receiverName, phoneNumber } = data;
  return (
    <>
      <div className="delivery-details">
        <Card>
          <h1 className="delivery-details-title">بيانات التوصيل</h1>
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '10px',
              width: '100%',
            }}
          >
            <div
              className=""
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '50px',
                width: '100%',
              }}
            >
              <div className="delivery-info">
                <h2>مستلم الطلب :</h2> <p>{receiverName}</p>
              </div>
              <div className="delivery-info">
                <h2>رقم الجوال :</h2>{' '}
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </div>
            </div>
            <div className="delivery-info">
              <h2>العنوان :</h2> <p>{deliveryAddress}</p>
            </div>
            <div className="delivery-info">
              <h2>المدينه :</h2> <p>{deliveryAddress}</p>
            </div>
            <div className="delivery-info">
              <h2>تاريخ التوصيل :</h2> <p>{deliveryDate}</p>
            </div>
            <div className="delivery-info">
              <h2>الوقت :</h2> <time>{deliveryDate}</time>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default DeliveryDetails;
