import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";


export const authLogin = catchAsync(async body => {
  const { data } = await axiosInstance.post('/Account/AdminLogin', body);
  return data;
});


export const authForgotPassword = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("/api/password/email", body);
  return data;
});

export const addPushToken = catchAsync(async body => {
  const { data } = await axiosInstance.post('/PushTokens', body);
  return data;
});

export const getNotificationsList = catchAsync(async () => {
  const { data } = await axiosInstance.get('/Notifications');
  return data;
});
