import { axiosInstance } from '../services/axiosInstance';
import catchAsync from '../utils/catchAsync';

export const getFlowersList = catchAsync(async (pageCount) => {
  const { data } = await axiosInstance.get(
    `/Product?Page=${pageCount}&Size=10&ProductType=1`
  );
  return data;
});
export const getFlowersDetails = catchAsync(async (Id) => {
  const { data } = await axiosInstance.get(`/Product/${Id}`);
  return data;
});
export const deleteProduct = catchAsync(async (Id) => {
  const { data } = await axiosInstance.delete(`/Product/${Id}`);
  return data;
});

export const addFlowers = catchAsync(async (body) => {
  const { data } = await axiosInstance.post('/Product', body);
  return data;
});

export const editFlower = catchAsync(async (body) => {
  const { data } = await axiosInstance.put('/Product', body);
  return data;
});

// Add customer products

export const addCustomerProduct = catchAsync(async (body) => {
  const { data } = await axiosInstance.post('/Product', body);
  return data;
});
