import './TermsConditions.scss';
const TermsConditions = () => {
  return (
    <>
      <div className="terms">
        <div className="containter">
          <h1 className="title-terms"> الشروط والاحكام </h1>
          <div className="container-terms">
            <div className="text-terms">
              <p style={{ fontSize: '28px', fontWeight: '600' }}>
                شروط الاستخدام
              </p>
              {/* <br /> */}
              <br />
              <p style={{ fontSize: '20px', fontWeight: '600' }}>
                مرحبا بكم في أفهمك.
              </p>
              <br />
              <p>
                هذه الصفحة (مع الوثائق المشار اليها فيه) تخبرك عن شروط الخدمة
                (&quot;الشروط&quot;) التي توضح لك عند طلب اي منتجات أو خدمات من
                عناصر القائمة (&quot;البنود&quot;) من موقعنا على الإنترنت أو
                تطبيقات أفهمك على الأجهزة المحمولة &quot;موبايل&quot; والنقالة
                والخدمات ذات الصلة (كل يشار إلى &quot;التطبيق&quot;).
              </p>
              <br />
              <p>
                يرجى قراءة هذه الشروط بعناية قبل طلب اي بنود من التطبيق. إذا كان
                لديك اي اسئلة تتعلق بهذه الشروط، يرجى الاتصال..........&nbsp;
              </p>
              <br />
              <br />
              <p>
                قبل ان تضع أي طلب. تم تحديث هذا المستند &quot;شروط الخدمة&quot;
                بتاريخ 09 مارس 2024 م.&nbsp;
              </p>
              <br />
              <p>
                إذا كنت مستهلكا، فلديك بعض الحقوق القانونية عند طلب الخدمات
                باستخدام تطبيقنا. لا تتأثر حقوقك القانونية بهذه الشروط، والتي
                تنطبق بالإضافة اليها ولا تحل محلها. عندما تقوم بإعداد حسابك
                الخاص لدى أفهمك، فإنك تؤكد موافقتك على هذه الشروط.
              </p>
              <br />
              <p>
                تشكل هذه الشروط اتفاقية ملزمة قانونًيا (&quot;الاتفاقية&quot;)
                بينك وبين أفهمك. تحكم هذه الاتفاقية استخدامك لتطبيقات أفهمك،
                وموقع الويب، ومركز الاتصال ومنصة التكنولوجيا (يُشار إليها مجتمعة
                بمنصة &quot;أفهمك&quot;).
              </p>
              <br />
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>
                معلومات عن أفهمك
              </p>
              <br />
              <p>
                تقوم شركة أفهمك لتقنية نظم المعلومات بتقديم وتشغيل خدمات التوصيل
                والحجوزات حسب طلبات العملاء من بنود الحجوزات من المطاعم و
                المشتريات بشتى أنواعها المحلية في المملكة العربية السعودية
                (متمثلة بصيغة &quot;الشركة&quot; أو ضمائر الملكية
                &quot;نحن&quot;، أو &quot;لدينا&quot; أو &quot;أفهمك&quot;) ،
                والتي تأسست في مدينة الرياض ، المملكة العربية السعودية ، حسب
                الترخيص رقم .............
              </p>
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>الغرض</p>
              <p>
                تتمحور فكرة خدمات أفهمك بغرض أن يسهل عليك متابعة مناسباتك الخاصة
                ويخططها لك ويساعدك بتقديم الخدمات أو الهدايا المتوفرة عن طريق
                التطبيق الخاص في أفهمك بواسطة شبكة الإنترنت، وعرض كافة الخدمات
                &nbsp;والمنتجات والسماح لعملاء أفهمك بطلب الخدمات المحددة من
                طرفهم ومن ثم تأدية مهمة التوصيل والتسليم.
              </p>
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>حسابك</p>
              {/* <br /> */}
              {/* <br /> */}
              <br />
              <p>
                قبل ان تتمكن من وضع أوامر طلبات للبنود باستخدام التطبيق لدينا،
                تحتاج إلى فتح حساب لدى أفهمك. عند فتحك الحساب، يمكنك إنشاء كلمه
                مرور، أو طريقه تسجيل دخول آمنه، وقد تحتاج أيضا إلى تقديم معلومات
                شخصية بالإضافة لتفاصيل بطاقة الائتمان.&nbsp;
              </p>
              <p>
                يجب الاحتفاظ بأي كلمة مرور تقوم بإنشائها، أو طريقه تسجيل دخول
                آمنه أخرى، وسريه، ومنع الآخرين من الوصول إلى حساب البريد
                الكتروني أو الهاتف المحمول الخاص بك.
              </p>
              <p>
                إذا كان شخص آخر يستخدم هذه الطرق للوصول إلى حسابك، ستكون مسؤولا
                عن دفع اي من البنود التي تطلب، ونحن لسنا مسؤولين عن اي خسائر
                أخرى تعاني منها، الا إذا كان الشخص الذي يستخدم كلمه المرور
                الخاصة بك حصل عليه لأننا لم نحافظ عليه أمنا.
              </p>
              <br />
              <p>
                يمكنك إغلاق حسابك في اي وقت عن طريق طلب القيام بذلك في قسم حسابك
                في موقعنا على التطبيق أو الاتصال بنا باستخدام بيانات الاتصال
                الواردة أعلاه.&nbsp;
              </p>
              <p>
                يجوز لنا تعليق وصولك إلى حسابك، أو إغلاقه بشكل دائم، إذا كنا
                نعتقد ان حسابك قد تم استخدامه من قبل شخص آخر. يجوز لنا أيضا
                إغلاق حسابك بشكل دائم إذا كنت في رأينا أنك تسيء استخدام خدمتنا
                (علي سبيل المثال، عن طريق التقدم بطلب لاسترداد الأموال أو
                الائتمان الذي لا نعتبره لك الحق به ، تقديم شكاوى متكررة غير
                معقولة ، أساءه معامله موظفينا ، أو أي سبب وجيه آخر) وسنقوم برد
                اي رصيد متبقي لحسابك الذي حصلت عليه بصوره صحيحه (لا تشمل العروض
                والأرصدة الممنوحة من طرف أفهمك لتحفيز العملاء كأرصدة مجانية)
                بواسطة فريق خدمه العملاء من خلال بطاقة الائتمان المسجلة الخاصة
                بك أو التحويل البنكي باستخدام التفاصيل البنكية (بشرط ان تكون قد
                زودتنا بها).&nbsp;
              </p>
              <br />
              <p>
                جميع الأرصدة المتوفرة داخل محفظة التطبيق الخاصة بك للاستخدام
                المستقبلي تعتبر نافذة، مع الأخذ بالاعتبار مدة الاستفادة من
                الرصيد المتبقي. تبقى نفاذية الصلاحية صالحة لمدة ثلاثة أشهر فقط
                من تاريخ آخر استخدام أو إيداع، ومن ثم يتم حذف الرصيد كاملا دون
                الالتزام بأية تعويضات.
              </p>
              <br />
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>توفر الخدمة</p>
              <p>
                كل الخدمات والهدايا المتوفرة في التطبيق يشمل تغطية على منطقه
                تسليم محدده، كما تم وصفها بمنصة أفهمك. قد تتغير منطقه التسليم
                هذه في اي وقت بسبب عوامل مثل الطقس، أو ضغط الطلب العالي على
                خدماتنا. يتم هذا الأمر للتأكد من ان البنود تصل إلى الباب الخاص
                بك في أفضل ما لدينا. جميع الأماكن التي توفر الخدمات والهدايا
                لدينا تقرر تحديد ساعات العمل الخاصة بها. إذا كنت تحاول ان تأمر
                بتسليم طلبك إلى موقع خارج منطقه التوصيل أو ساعات العمل من مطعم
                شريك، أو التطبيق غير متوفر لأي سبب من الأسباب، فسوف نعلمك ان
                الطلب لن يكون ممكنا.
              </p>
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>الطلبات</p>
              <br />
              {/* <br /> */}
              <p>
                عند وضع أي طلب من خلال التطبيق لدينا، قبل ان يتم تأكيد ذلك، فانه
                يحتاج إلى ان تكون مقبولة من طرفنا. سنرسل لك نصا كإشعار إذا تم
                قبول طلبك (&ldquo;اشعار التأكيد&quot;). جميع البنود هي رهن
                بالتوافر. هذا العقد لتوريد اي بند كنت قد أمرت به يأتي إلى حيز
                التنفيذ عندما نرسل اشعار التأكيد. أنت مسؤول عن دفع ثمن جميع
                البنود المطلوبة باستخدام حسابك، وعن رسوم التوصيل ذات الصلة، وعن
                الامتثال لهذه الشروط، حتى لو كنت قد طلبت البند لشخص آخر.&nbsp;
              </p>
              <br />
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>التوصيل</p>
              <br />
              <p>
                عند وضعك أمر للطلب، سيكون لدينا الخيار لوضعه كتوصيل وسوف نوضح
                تتبع طلبكم حسب الإجراءات لحين وقت التسليم المقدرة للبند الخاص
                بك، من خلال وضع النظام، ولكن سوف نحاول التسليم في أقرب وقت ممكن؛
                لذلك يجب ان تكون متاحا لقبول الاستلام من الوقت التي تضع الطلب في
                التطبيق. بالرغم مما لدينا من جهود، لسبب ما، إن الأمور قد لا تكون
                دائما حسب الخطة وقد تتسم بعوامل مثل حركة المرور و/أو الأحوال
                الجوية والتي قد تمنعنا من تسليم الطلب في الوقت المحدد. إذا كان
                طلبك متأخرا لأكثر من 120 دقيقه، ولم نخطرك بإعطائك الخيار لإلغاء
                طلبك، سنعمل معاً لتطون الخدمة تحت السيطرة، الا إذا كان سبب
                التأخير من العميل او من طرف مستلم الهدية (على سبيل المثال، لأنك
                قدمت لنا عنوانا خاطئا أو لم تأت إلى الباب لاستلام الطلب).
              </p>
              <br />
              <p>
                سوف نقوم بمحاولة التوصيل في العنوان الذي تقدمه لنا عند وضعك
                للطلب. سوف تكون أنت المسؤول عن التأكد من أنك قمت بإدخال موقع
                دقيق لضمان وصول الطلب اليك. إذا كنت بحاجه إلى تغيير موقع التسليم
                بعد ان كنت قد وضعت الطلب الخاص بك، فمن الممكن العمل على تغيير
                موقع التسليم إلى العنوان البديل بشرط ان تقوم بإشعارنا قبل ان يتم
                إرسال السائق، والعنوان الجديد هو ضمن إطار الموقع السابق للطلب.
                إذا لم نتمكن من تغيير عنوان التوصيل، لديك الخيار لإلغاء الطلب،
                ولكن إذا بدأت إجراءات تجهيز الخدمة مثل شراء المنتج سيتم احتساب
                السعر الكامل للمنتج، وإذا تم استلام الطلب للتوصيل فسيتم أيضا فرض
                رسوم التوصيل أيضاً.
              </p>
              <p>
                سيظل بالإمكان محاسبتك على قيمة المنتج والتوصيل في حاله التسليم
                الفاشل، إذا كنت قد تسببت في مثل هذا الفشل لأي سبب من الأسباب.
                تتضمن الأسباب التي قد تؤدي إلى فشل التسليم، ولكن لا تقتصر على:
              </p>
              <p>كنت لا تأتي إلى الباب أو من قبل مستقبل الهدية.</p>
              <p>
                لم تستقبل الاتصال الهاتفي عند اتصال السائق بك أو مستقبل الهدية،
                باستخدام معلومات الاتصال التي قدمتها لنا؛ و/ أوكنت استقبلت
                الاتصال الهاتفي، ولكن بعد ذلك فشلت في توفير الوصول للسائق في
                غضون فتره معقولة من الزمن (20 دقيقة)، و/أو أن السائق غير قادر
                على العثور على مكان آمن لترك الهدية.
              </p>
              {/* <br /> */}
              {/* <br /> */}
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>حقوقك</p>
              <br />
              <p>إذا كان هناك شيء خاطئ مع البنود الخاصة بنا</p>
              <br />
              <p>
                لديك الحق القانوني في الحصول على السلع التي تمتثل لوصفها بالطلب،
                والتي هي من نوعيه مرضيه والتي تمتثل لأي متطلبات محدده تخبرنا
                عنها (بالموافقة عليها من جميع الاطراف) قبل ان تضع طلبك. إذا كنت
                تعتقد ان البنود التي تم تسليمها لا تمتثل لهذه الحقوق القانونية،
                الرجاء إشعارنا بذلك مباشرة للمعرفة. قد نطلب صوره تظهر المشكلة،
                إذا كان هناك شيء يمكن رؤيته من خلال فحص البنود. سنقوم بتوفير
                المبلغ المسترد أو إيداع الرصيد الخاص بالحساب داخل المحفظة
                المعنية بك لدى تطبيق أفهمك، فيما يتعلق بالجزء المتأثر من السلعة،
                وكذلك فيما يتعلق بالتوصيل، إذا تأثر الأمر بأكمله، الا إذا كان
                لدينا سبب معقول للاعتقاد بان المشكلة قد حدثت بعد التوصيل. قبل
                معالجة مبلغ الاسترداد أو رصيد الحساب، قد نأخذ في الاعتبار
                العوامل ذات الصلة بما في ذلك تفاصيل الطلب، وكذلك تاريخ حسابك،
                وما حدث عند التوصيل.
              </p>
              {/* <br /> */}
              {/* <br /> */}
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>الإلغاء</p>
              <br />
              <p>
                يمكنك إلغاء أمر طلبك بدون رسوم في اي وقت قبل ان يبدا المندوب في
                تجهيز طلبك &quot;الأمر الذي بدأ&quot;، الرجاء مباشرة الإلغاء عن
                طريق تطبيق أفهمك. إذا قمت بإلغاء اي طلب بعد ان يصبح أمر البدء
                فاعلاً، سيتم احتساب السعر الكامل للعناصر، وإذا تم تفعيل أمر
                إسناد السائق فسوف سيتم فرض رسوم على التوصيل أيضاً. بالنسبة لأي
                أمر مطلوب على أساس الدفع عند التوصيل (&quot;الدفع عند
                التوصيل&quot;) النقدي أو الخصم من الحساب البنكي عند التسليم، أو
                من رصيد المحفظة المتوفرة لديك عبر التطبيق، مثل هذه الحالة للطلب
                قد لا يتم إلغاء هذا الأمر بعد ان يصبح &quot;أمرا بدأ&quot; وسيتم
                فوترته وتسليمه لك. في المقابل، قد يتم إشعارك من طرف أفهمك من
                خلال التطبيق بأنه قد تم إلغاء الطلبية في اي وقت. في هذه الحالة،
                لن يتم محاسبتك عن اي أوامر تم إلغاؤها من قبلنا وسوف نقوم بتعويضك
                عن اي عمليه دفع تمت بالفعل باستخدام نفس الطريقة التي استخدمتها
                للدفع مقابل طلبك.
              </p>
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>
                الأسعار والدفع والعروض
              </p>
              <br />
              <p>
                أفهمك تستخدم نظام تشغيل التسعير الديناميكي كل الوقت للفواتير
                الصادرة، وسيتم تحديد السعر الإجمالي لطلبك في صفحه الخروج على
                التطبيق، بما في ذلك أسعار المنتجات والتوصيل ورسوم الخدمة المعمول
                بها والضرائب، والتي تشمل التفاصيل التالية: -
              </p>
              <br />
              <p>عدد الخدمات ونوعيتها</p>
              <p>
                سعر الوحدة، حسب لائحة أسعار المنتج والموضحة على تطبيق أفهمك.
              </p>
              <p>رسوم التوصيل لموقع العميل من قبل سائقي أفهمك.</p>
              <p>
                قيمة الضريبة المضافة شاملة للمنتجات والخدمات وتشمل 15% من إجمالي
                القيمة المقدمة من أفهمك.
              </p>
              <p>وقائع زمن وتاريخ تقديم الطلب</p>
              <p>رقم الفاتورة ورقم الترخيص لضريبة القيمة المضافة للمنتج.</p>
              <p>إجمالي قيمة الفاتورة المطلوب تسويتها من قبل العميل.</p>
              <br />
              <br />
              <p>
                وهو ما يعني ان أسعار البنود والتوصيل قد تتغير اثناء التصفح.
                ويمكن تغيير الأسعار في اي وقت وفقا لتقدير صاحب المنتج وقيمة
                التوصيل نظراً لبعد أو قرب المسافة من المحل لموقع طلب العميل
                أيضاً. نحن نحتفظ بالحق في فرض رسوم خدمة التوصيل، والتي قد تكون
                عرضه للتغيير، لتوفير تقديم خدماتنا. سيتم اخطارك بأي رسوم خدمه
                تقدم وضرائب مطبقه قبل الشراء على صفحة الخروج في تطبيق أفهمك.
              </p>
              <p>
                جميع الأسعار المقدمة حسب الفاتورة الصادرة على تطبيق أفهمك تكون
                مطابقة للمطالبة حسب الفاتورة الخاصة بالمحل بالإضافة لضريبة
                القيمة المضافة. لن تؤثر اي تغييرات على الأوامر المؤكدة الموجودة،
                ما لم يكن هناك خطا تسعير واضح. ولن تؤثر التغييرات في الأسعار على
                أي أوامر في العملية والتي تظهر داخل سلتك، شريطه إكمال الطلب خلال
                وقت إنشاء السلة. إذا لم تقم بإنهاء تحديد البنود خلال إنشاء
                الطلب، فسيتم إزالة البنود من سلتك تلقائيا.&nbsp;
              </p>
              <p>
                إذا كان هناك خطا واضح في التسعير، فسوف نخبرك بأقرب وقت ممكن
                وسيكون لديك خيار تأكيد الطلب في السعر الأصلي أو الإلغاء دون
                تكلفه ومع استرداد كامل المبلغ من اي أموال دفعت بالفعل داخل محفظة
                العميل على تطبيق أفهمك بحيث تتم الاستفادة من المبلغ لمدة أقصاها
                ثلاثة شهور من تاريخ آخر إيداع مبلغ بالمحفظة. ويمكن اجراء الدفع
                لجميع أسعار البنود والتوصيل على التطبيق الخاص بنا، إما عن طريق
                النقد عند التوصيل، بطاقات الائتمان، أو الخصم من المحفظة الخاصة
                بالعميل لدى أفهمك، أو طرق الدفع الأخرى التي توفرها أفهمك. حالما
                يتم تأكيد طلبك، سيتم تفويض بطاقة الائتمان أو الخصم الخاصة بك
                والمبلغ الإجمالي المحدد للدفع (فيما يتعلق بجميع المدفوعات التي
                تتم بواسطة بطاقات الائتمان أو الخصم).
              </p>
              <br />
              <p>
                يتم الدفع مباشره إلى أفهمك الذي يعمل كوكيل نيابة عن صاحب المنتج
                والسائق فقط. ويمكن أيضا اجراء الدفع باستخدام رصيد الحساب.
                استخدام هذه يخضع لشروط ائتمان حساب عندما يكون الدفع نقدا عند
                التوصيل ممكنا، سيتم توضيح ذلك علي التطبيق الخاص بنا قبل أن تضع
                طلبك. سيتم قبول الدفعات النقدية فقط عندما يتم تسليم الطلبية
                بموجب تحديد مرفق إمكانية الدفع نقدا عند التوصيل.
              </p>
              <p>
                عندما يكون الدفع ببطاقة الدفع البنكية (بطاقات ائتمانية بنكيه)
                عبر الإنترنت ممكنا، سيتم توضيح ذلك على التطبيق الخاص بنا قبل أن
                تضع طلبك. سيتم قبول الدفعات البنكية فقط عندما يتم تسليم الطلبية
                بموجب تحديد مرفق إمكانية الدفع &quot;بطاقة بنكية عند
                التسليم&quot;.
              </p>
              <p>
                لن يكون مصرحا لنا بقبول الشيكات، وائتمانات التسليم، والقسائم،
                والدفع بالبطاقات أو اي شكل آخر من اشكال الدفع (بما في ذلك بطاقات
                الائتمان أو الخصم) بمجرد طلب الطلبية من خلال دفع المبلغ نقدا عند
                التوصيل على خدمتنا. يتوفر للسائقين حمل كمية قليلة كافية لصرف
                للمبالغ النقدية، وذلك لجميع المدفوعات النقدية عند التسليم، لذلك
                يطلب منك اعداد المبلغ المطلوب بالضبط، أو أقرب ما يمكن لقيمة
                الطلب (كما قد لا تتلقي باقي المبلغ). نحن مخولون من قبل شركائنا
                المطاعم لقبول الدفع نيابة عنهم ودفع ثمن اي بنود أو رسوم التوصيل
                لنا سوف تفي بالتزامك لدفع الثمن إلى المطعم الشريك.
              </p>
              <p>
                تقدم المطاعم الشريكة في بعض الأحيان توفير عروضا خاصه من خلال
                تطبيقنا. هذه الحالات تكون مرئية عند النظر إلى قائمه مطعم الشريك
                فقط من خلال تطبيق أفهمك. هذه العروض هي وفقا لتقدير المطاعم
                الشريكة. وما لم تحدد شروط العرض فتره محدده أو أقل والتي سيكون
                العرض متاحا لها، فانه يمكن سحبها في اي وقت ، الا إذا كنت قد وضعت
                بالفعل أمرا للطلب بناء على العرض وأرسلنا لك اشعار التأكيد.
              </p>
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>
                مسؤوليتنا عن الخسارة أو الضرر الذي تعانيه
              </p>
              <br />
              <p>
                نحن مسؤولون امامك عن اي خسارة أو ضرر تعاني منه والتي تعتبر نتيجة
                متوقعه لخرقنا لهذه الشروط أو لعدم استخدام الرعاية المعقولة
                والمهارة فيما يتعلق باستخدامك لخدماتنا. نحن لسنا مسؤولين عن اي
                خسارة أو ضرر غير متوقع. الخسارة أو الضرر &quot;متوقع&quot; هو
                إذا كان من الواضح انه سيحدث، أو إذا قلت لنا انه قد يحدث، على
                سبيل المثال إذا كنت تخبرنا عن ظروف معينه قد تزيد من الخسارة أو
                الضرر الناجم عن خرقنا لهذه الشروط قبل أن تضع الطلب. نحن لا
                نستبعد أو نحد من مسؤوليتنا تجاهك عن الخسارة أو الضرر حيث يكون من
                غير القانوني القيام بذلك. وهذا يشمل اي مسؤوليه عن الإصابة
                الشخصية الناجمة عن فشلنا، أو فشل موظفينا أو وكلائنا أو المتعهدين
                من الباطن، أو شركائنا المطاعم لاستخدام الرعاية والمهارة
                المعقولة؛ للاحتيال أو تحريف احتيالي؛ أو لانتهاك حقوقك القانونية
                فيما يتعلق بالبنود، كما هو ملخص في الجزء رقم 7 أعلاه.
              </p>
              <br />
              <p>
                ومع مراعاة الفقرة السابقة، فإننا لسنا مسؤولين عن اي خسارة أو ضرر
                تعانيه نتيجة لخرقك الخاص لهذه الشروط، أو نتيجة لأي فشل في أجهزه
                تكنولوجيا الحاسوب أو برامج أخرى عدى الفشل في تطبيقاتنا.
              </p>
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>
                حماية البيانات
              </p>
              <br />
              <p>
                نقوم بمعالجه بياناتك الشخصية وفقا لسياسة الخصوصية الخاصة بنا
                والتي يمكن العثور عليها ضمن تطبيق أفهمك.
              </p>
              <br />
              <p style={{ fontSize: '20px', fontWeight: '500' }}>
                شروط أخرى
              </p>{' '}
              <br />
              <p>
                إذا كان لدينا أو لديك اي حق لفرض هذه الشروط ضد الآخر، فان هذا
                الحق لن يضيع، حتى لو كان الشخص الذي لديه الحق في التأخير إنفاذه،
                أو يتنازل عن حقه في إنفاذه في اي حال من الحالات. إذا قررت محكمة
                أو سلطة أخرى أن أي جزء من هذه الشروط غير قانوني أو غير فعال، فان
                بقية الشروط لن تتأثر وستظل ساريه المفعول.
              </p>
              <p>
                قد نقوم بتغيير هذه الشروط من وقت لآخر. إذا قمنا بإجراء أي
                تغييرات تؤثر على حقوقك فيما يتعلق بخدمتنا، فسوف نخبرك. لن تؤثر
                التغييرات على الشروط على أي أوامر قمت بوضعها في المكان الذي
                أرسلنا فيه إشعار التأكيد.&nbsp;
              </p>
              <p>
                تخضع هذه الشروط لقانون المملكة العربية السعودية ، ويمكنك رفع
                الإجراءات القانونية المتعلقة بخدمتنا في محاكم السلطة المختصة.
                تعتبر اللغة العربية في هذا المستند هي اللغة المعتمدة بالرجوع إلى
                الشروط الموضحة أعلاه.
              </p>
              <p>
                تم تحديث هذا المستند &quot;شروط الاستخدام&quot; بتاريخ 09 مارس
                2024.&nbsp;
              </p>
              <p>VAT Registration Certificate</p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
