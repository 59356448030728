import { useMutation } from "@tanstack/react-query";
import { App, Button, Form, Input } from "antd";
// import BrandLogo from 'components/common/BrandLogo';
// import { authLogin } from 'network/auth';
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BrandLogo from "../../../components/layout/Header/BrandLogo/BrandLogo";
import { authLogin } from "../../../network/auth";
import { setStoredToken } from "../../../services/user-storage";
import { setUserData } from "../../../services/store/slices/user";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message } = App.useApp();
  const { mutate: onAuthLogin, isLoading } = useMutation(authLogin, {
    onSuccess: res => {
      console.log('Login  res:', res);
      if (!res)
        return message.error(
          'الرجاء إدخال البريد الإلكتروني الصحيح وكلمة المرور. لاحظ أن كلا الحقلين قد يكونا حساسين لحالة الأحرف.'
        );
      const userData = res?.model;
      setStoredToken(userData?.token);
      dispatch(setUserData(userData));
      message.success(`مرحباً بك ${userData?.userName || userData?.name}`);
      navigate('/');
    },
    onError: error => {
      console.log('Login  error:', error);
      message.error(
        'الرجاء إدخال البريد الإلكتروني الصحيح وكلمة المرور. لاحظ أن كلا الحقلين قد يكونا حساسين لحالة الأحرف.'
      );
    },
  });

  return (
    <div className="auth-box-wrapper">
      <div className="auth-box-holder">
        <div className="flex justify-center mb-9">
          <BrandLogo />
        </div>
        <Form
          className="form-style"
          layout="vertical"
          onFinish={onAuthLogin}
        >
          <Form.Item name="email" label="البريد الالكتروني" rules={[{ required: true, type: 'يرجى إدخال البيانات الصحيحة' }]}>
            <Input placeholder="اكتابة البريد الإلكتروني او اسم المستخدم" />
          </Form.Item>
          <Form.Item
            name="password"
            label="كلمة المرور"
            rules={[{ required: true, type: 'يرجى إدخال الرمز المرور الصحيحة' }]}
          >
            <Input.Password placeholder=" اكتب كلمة المرور" />
          </Form.Item>

          <Button htmlType="submit" type="primary" block loading={isLoading}>
            تسجيل الدخول
          </Button>
          <p className="account-register-row">
            <Link to="/auth/forget-password"> اضغط هنا </Link>
            ?
            هل نسيت كلمة المرور
          </p>
        </Form>
      </div>
    </div>
  );
}

export default Login;
