// import Login from "pages/auth/Login";
// import Register from "pages/auth/Register";
// import Register from "pages/auth/Register";

import TermsConditions from '../pages/Terms-Conditions/TermsConditions';
import ForgotPassword from '../pages/auth/forgot-password/ForgotPassword';
import Login from '../pages/auth/login/Login';

const routesAuthentication = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: 'terms-conditions',
    element: <TermsConditions />,
  },
  {
    path: 'auth',
    children: [
      {
        path: 'forget-password',
        element: <ForgotPassword />,
      },
    ],
  },
];

export default routesAuthentication;
