import { Checkbox, Form, Popconfirm, Spin, message } from 'antd';
import {  useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {  deleteCategory, getCategoryAge, getCategoryGander, getCategorys } from '../../network/category';
import { queryKeys } from '../../services/react-query/queryKeys';
import { useEffect, useState } from 'react';
import {CloseOutlined} from "@ant-design/icons"

export const CheckboxCategoryGender = ({ setValues, valueCheck, value,Delete }) => {
  const [idCategory, setIdCategory] = useState([]);
  console.log("idCategory : ", idCategory);
  const checked = valueCheck?.map((item) => item?.category?.id);
  const client = useQueryClient();

  const handleRemoveCategory = useMutation(
    () => deleteCategory(idCategory),
    {
    onSuccess: (res) => {
      console.log('  res onSuccess :', res);
      if (res) {
        message.success("تم حذف الفئة");
        client.invalidateQueries([queryKeys.categoryGanderList]);
      } else {
        message.error("حدث خطأ ما");
      }
    },
    onError: (error) => {
      console.log('error :', error);
      message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
    },
  });
  const confirmCategory = () => {
    handleRemoveCategory.mutate();
  };
  const cancelCategory = () => {
    message.info('لم يتم حذف الفئة');
  };
  const { data: categoryGender, isLoading } = useQuery(
    [queryKeys.categoryGanderList],
    getCategoryGander
  );

  useEffect(() => {
    const selectValues = categoryGender?.model?.filter((item) =>
      checked?.includes(item?.id)
    );
    if (!value) {
      setValues(selectValues);
    }
  }, [value, checked, categoryGender, setValues]);
  if (isLoading) {
    return <Spin />;
  }
  return (
    <>
    <Checkbox.Group
      onChange={(value) => {
        setValues(value);
      }}
      defaultValue={checked}
    >
      {Delete ? (
        categoryGender?.model?.map((item) => (
          <Checkbox
            key={item?.id}
            value={item?.id}
            style={{ position: "relative" }}
          >
            <span className="closeItem" onClick={() => setIdCategory(item?.id)}>
              <Popconfirm
                title="احذف التصنيف ؟"
                description="هل انت متاكد من حذف التصنيف ?"
                onConfirm={confirmCategory}
                onCancel={cancelCategory}
                okText="نعم"
                cancelText="لا"
              >
                <CloseOutlined />
              </Popconfirm>
            </span>{" "}
            {item?.name}
          </Checkbox>
        ))
      ) : (
        categoryGender?.model?.map((item) => (
          <Checkbox
            key={item?.id}
            value={item?.id}
            style={{ position: "relative" }}
          >
            {item?.name}
          </Checkbox>
        ))
      )}
    </Checkbox.Group>
    </>
    
  );
};


export const CheckboxCategoryAge  = ({setValues,valueCheck,value,Delete}) => {
  const [idCategory, setIdCategory] = useState([]);
  const client = useQueryClient();
  const handleRemoveCategory = useMutation(
    () => deleteCategory(idCategory),
    {
    onSuccess: (res) => {
      console.log('  res onSuccess :', res);
      if (res) {
        message.success("تم حذف الفئة");
        client.invalidateQueries([queryKeys.categoryAgeList]);
      } else {
        message.error("حدث خطأ ما");
      }
    },
    onError: (error) => {
      console.log('error :', error);
      message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
    },
  });
  const confirmCategory = () => {
    handleRemoveCategory.mutate();
  };
  const cancelCategory = () => {
    message.info('لم يتم حذف الفئة');
  };


  const checked = valueCheck?.map(item => item?.category?.id)
    const { data: categoryAge, isLoading  } = useQuery(
        [queryKeys.categoryAgeList],
        () => getCategoryAge()
      );
      useEffect(() => {
        const selectValues = categoryAge?.model?.filter(item =>
          checked?.includes(item?.id)
        );
        if (!value) {
          setValues(selectValues);
        }
      }, [value, checked, categoryAge, setValues]);
    if(isLoading){
      return <Spin  />;
    }
    return (
        <Checkbox.Group
        onChange={(value) => {
            console.log(value, "value-onchange");
            setValues(value)
        }}
        defaultValue={checked}
        >
          {Delete ? (
            categoryAge?.model?.map((item) => (
              <Checkbox
                key={item?.id}
                value={item?.id}
                style={{ position: "relative" }}
              >
                <span
                  className="closeItem"
                  onClick={() => {
                    setIdCategory(item?.id);
                  }}
                >
                  <Popconfirm
                    title="احذف التصنيف ؟"
                    description="هل انت متاكد من حذف التصنيف ?"
                    onConfirm={confirmCategory}
                    onCancel={cancelCategory}
                    okText="نعم"
                    cancelText="لا"
                  >
                    <CloseOutlined />
                  </Popconfirm>
                </span>{" "}
                {item?.name}
              </Checkbox>
            ))
          ) : (
            categoryAge?.model?.map((item) => (
              <Checkbox
                key={item?.id}
                value={item?.id}
                style={{ position: "relative" }}
              >
                {item?.name}
              </Checkbox>
            )) 
          )}
        </Checkbox.Group>
    )
}
export const CheckboxCategorys  = ({setValues,valueCheck,value,Delete}) => {
  const [idCategory, setIdCategory] = useState([]);
  const client = useQueryClient();
  const handleRemoveCategory = useMutation(
    () => deleteCategory(idCategory),
    {
    onSuccess: (res) => {
      console.log('  res onSuccess :', res);
      if (res) {
        message.success("تم حذف الفئة");
        client.invalidateQueries([queryKeys.categoryList]);
      } else {
        message.error("حدث خطأ ما");
      }
    },
    onError: (error) => {
      console.log('error :', error);
      message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
    },
  });
  const confirmCategory = () => {
    handleRemoveCategory.mutate();
  };
  const cancelCategory = () => {
    message.info('لم يتم حذف الفئة');
  };

  const checked = valueCheck?.map(item => item?.category?.id)
    const { data: options, isLoading:loadOption  } = useQuery(
        [queryKeys.categoryList],
        () => getCategorys()
      );
      useEffect(() => {
        const selectValues = options?.model?.filter(item =>
          checked?.includes(item?.id)
        );
    
        if (!value) {
          setValues(selectValues);
        }
      }, [value, checked, options, setValues]);
    if(loadOption){
      return <Spin  />;
    }
    return (
        <Checkbox.Group
        onChange={(value) => {
            // console.log(value);
            setValues(value)
        }}
        defaultValue={checked}
        >
          {Delete ? (
            options?.model?.map((item) => (
              <Checkbox
                key={item?.id}
                value={item?.id}
                style={{ position: "relative" }}
              >
                <span
                  className="closeItem"
                  onClick={() => {
                    setIdCategory(item?.id);
                  }}
                >
                  <Popconfirm
                    title="احذف التصنيف ؟"
                    description="هل انت متاكد من حذف التصنيف ?"
                    onConfirm={confirmCategory}
                    onCancel={cancelCategory}
                    okText="نعم"
                    cancelText="لا"
                  >
                    <CloseOutlined />
                  </Popconfirm>
                </span>{" "}
                {item?.name}
              </Checkbox>
            ))
          ) : (
            options?.model?.map((item) => (
              <Checkbox
                key={item?.id}
                value={item?.id}
                style={{ position: "relative" }}
              >
                {item?.name}
              </Checkbox>
            )) 
          )}
        </Checkbox.Group>
    )
}