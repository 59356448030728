import { Card } from "antd";
import React from "react";
import "./Home.scss";
import { BsTicketPerforated } from "react-icons/bs";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { CiClock2 } from "react-icons/ci";
import { AiOutlineChrome } from "react-icons/ai";
import { HiOutlineUsers } from "react-icons/hi2";
import { useQuery } from "@tanstack/react-query";
import { getDashboard } from "../../network/Dashboard";
import { queryKeys } from "../../services/react-query/queryKeys";
import { DashboardLoading } from "../../components/Loading/Loading";
export default function Home() {
  const {data: dashboard, isLoading,isFetching} = useQuery([queryKeys.dashboard], 
    () => getDashboard(),
    {
      keepPreviousData: true
    }
    )
    console.log("dashboard", dashboard);
  const getDashboardStatusStyle = (order) => {
    let color = "";
    let icon = "";
    switch (order) {
      case "مجموع الطلبات":
        color = "#484C52";
        icon = <BsTicketPerforated />;
        break;
      case "الطلبات المكتملة":
        color = "#167767";
        icon = <AiOutlineCheckCircle />;
        break;
      case "الطلبات قيد الاجراء":
        color = "#EE7501";
        icon = <CiClock2 />;
        break;
      case "الطلبات قيد التنفيذ":
      case "التوصيل":
        color = "#40C1AC";
        icon = <AiOutlineChrome />;
        break;
      case "عدد المستخدمين":
        color = "#6D29F6";
        icon = <HiOutlineUsers />;
        break;
      default:
        break;
    }
    return { color, icon };
  }
    if(isLoading || isFetching) return (<DashboardLoading/>)
  return (
    <>
      <div className="dashboard">
        <div className="dashboard-title">
          <h1 className="dashboard-title-text">لوحة التحكم</h1>
        </div>
        <div className="dashboard-card">
          {dashboard?.model?.map((item, index) => (
             <Card
             key={index}
             style={{
              width: 192,
              height:138,
              borderColor:"rgba(143, 155, 179, 0.50)",
              borderRadius: 12,
             }}
           >
            <div className="dashboard-card-item">
              <div className="card-title-dashboard-container">
                <span className="card-icon-dashboard" 
                style={{
                  color: getDashboardStatusStyle(item?.nameAr)?.color,
                }}
                >
                  {getDashboardStatusStyle(item?.nameAr)?.icon}
                </span>
                <h3 className="card-title-dashboard"
                style={{
                  color: getDashboardStatusStyle(item?.nameAr)?.color,
                }}
                >{item?.nameAr}</h3>
              </div>
              <div className="card-content">
                <h2 className="card-content-dashboard">{item?.count}</h2>
              </div>
            </div>
           </Card>
          ))}
        </div>
      </div>
    </>
  )
}
