import { Button, Form, Input, InputNumber, Modal, Select, message } from 'antd';
import { useState } from 'react';
import './EditPrice.scss';
import { updatePrice } from '../../../network/order';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { EditOutlined } from '@ant-design/icons';
const EditPrice = ({ handleEdit, setHandleEdit, editCustomerPrice }) => {
  // console.log(handleEdit, "handleEdit");
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const client = useQueryClient();
  const { id, price } = handleEdit || {};

  const handleReset = () => {
    form.resetFields();
    setHandleEdit(null);
    setModalVisible(false);
  };
  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    handleReset();
    message.info('لم يتم تعديل السعر');
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: updatePrice,
    onSuccess: (res) => {
      console.log('  res onSuccess :', res);
      if (res) {
        message.success('تم تعديل السعر بنجاح');
        handleReset();
        client.invalidateQueries([queryKeys.orderDetails]);
      } else {
        message.error('حدث خطأ ما');
      }
    },
    onError: (error) => {
      console.log('error :', error);
      message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
    },
  });
  const onFinish = (values) => {
    console.log('Form Values', values);
    const body = {
      itemId: id,
      itemPrice: values.currentPrice,
    };
    mutate(body);
  };
  return (
    <>
      <div className="edit-price-add-header">
        {editCustomerPrice ? (
          <Button
            type="link"
            onClick={() => {
              setModalVisible(true);
            }}
            style={{
              fontSize: '20px',
              fontWeight: '600',
            }}
          >
            {' '}
            <EditOutlined /> تعديل{' '}
          </Button>
        ) : (
          <span
            onClick={() => {
              setModalVisible(true);
            }}
          >
            تعديل السعر
          </span>
        )}
      </div>
      <div className="edit-price-add-body">
        <Modal
          title="تعديل قيمة الهدية"
          open={modalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            initialValues={{
              previousPrice: price,
            }}
            name={`form_item_path_edit_price_${id}`}
            // name="form_item_path_edit_price"
            layout="vertical"
            className="form-item-edit w-auto align-end justify-end py-6 gap-6 flex flex-col"
            onFinish={onFinish}
            form={form}
          >
            <Form.Item name="previousPrice" label="السعر السابق">
              <Input placeholder="السعر السابق" disabled />
            </Form.Item>
            <Form.Item
              name="currentPrice"
              label="السعر الحالي"
              rules={[
                {
                  required: true,
                  message: 'يجب اضافة السعر الحالي',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="السعر الحالي"
              />
            </Form.Item>
            <Form.Item className="flex align-center gap-22 justify-end">
              <Button
                type="text"
                onClick={handleCancel}
                className="justify-center align-center size-[14px] text-[#8F9BB3]"
              >
                إلغاء
              </Button>
              <Button
                htmlType="submit"
                type="default"
                className="mr-6 h-[37px] w-[125px] justify-center align-center rounded-md gap-3 size-[14px] text-[#8F9BB3]"
                loading={isLoading}
              >
                تعديل
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default EditPrice;
