import './RestaurantEdit.scss';
import { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Form,
  Input,
  Popconfirm,
  Skeleton,
  Upload,
  message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from '../../../services/react-query/queryKeys';
import {
  deleteRestaurant,
  getRestaurantDetails,
  updateRestaurant,
} from '../../../network/restaurants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EditRestaurantLoading } from '../../Loading/Loading';
import { deleteImage } from '../../../network/products';
const RestaurantEdit = () => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const { IdEdit } = useParams();
  console.log(IdEdit, 'IdEdit');
  const uploadButton = (
    <div>
      <PlusOutlined type="plus" className="upload-btn-wrapper-edit" />
      <div className="upload-btn">اضافة صور</div>
    </div>
  );
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isImageClicked, setIsImageClicked] = useState(false);
  console.log(fileList, 'fileListArr');
  const imageList = Form.useWatch('RestaurantImages', form);
  console.log(imageList, 'imageList');
  useEffect(() => {
    setFileList(
      imageList?.map((item) => {
        return {
          uid: item?.id,
          name: item?.name,
          status: 'done',
          thumbUrl: item?.imageURL || item?.thumbUrl,
          originFileObj: item?.originFileObj,
          id: item?.id,
        };
      })
    );
    // setFileList(imageList)
  }, [imageList]);
  const { mutate: EditRestaurant, isLoading: isLoadingEdit } = useMutation(
    updateRestaurant,
    {
      onSuccess: (res) => {
        if (res) {
          message.success('تم تعديل المطعم بنجاح');
          client.invalidateQueries([queryKeys.restaurantList]);
          navigate('/restaurant');
        } else {
          message.error('حدث خطأ ما');
        }
      },
      onError: () => {
        message.error('حدث خطأ ما');
      },
    }
  );
  const onFinish = (values) => {
    const formData = new FormData();
    formData.append('Id', IdEdit);
    formData.append('name', values?.name);
    formData.append('description', values?.description);
    formData.append('city', values?.city);
    formData.append('websiteURL', values?.websiteURL);
    formData.append('Classification', values?.classification);
    values?.RestaurantImages?.forEach((item) => {
      formData.append(
        'RestaurantImages',
        item?.originFileObj || item?.thumbUrl
      );
    });
    EditRestaurant(formData);
  };
  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('حجم الصورة يجب ان يكون اقل من 2 ميجا بايت');
      return false;
    }
    return isLt2M;
  };
  const handleUpload = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const handleRemove = useMutation(() => deleteRestaurant(IdEdit), {
    onSuccess: () => {
      message.success('تم الحذف بنجاح');
      client.invalidateQueries([queryKeys.restaurantList]);
      navigate('/restaurant');
    },
    onError: () => {
      message.error('حدث خطأ ما');
    },
  });
  const removeImage = useMutation((id) => deleteImage(id), {
    onSuccess: (res) => {
      if (res) {
        message.success('تم الحذف الصورة بنجاح');
        client.invalidateQueries([queryKeys.productList]);
      } else {
        message.error('حدث خطأ ما');
      }
    },
    onError: () => {
      message.error('حدث خطأ ما');
    },
  });
  const handelRemove = (item) => {
    console.log('item', item);
    const { id } = item;
    return removeImage.mutate(id);
  };
  const confirm = (e) => {
    handleRemove.mutate();
  };
  const cancel = (e) => {
    message.error('لم يتم الحذف');
  };
  const { data: restaurantDetails, isLoading } = useQuery(
    [queryKeys.restaurantList, IdEdit],
    () => getRestaurantDetails(IdEdit)
  );
  const { name, description, websiteURL, city, classification, giftImages } =
    restaurantDetails?.model || {};
  if (isLoading) {
    return <EditRestaurantLoading />;
  }
  return (
    <div className="Products-edit">
      <>
        <div className="products-edit-header flex justify-between align-center w-full h-[56px] text-[#6D29F6]">
          <Button type="link" className="category-add-btn-bold">
            <Link to="/restaurant">المطعم</Link>
          </Button>
          <div className="category-add-btns">
            <Button
              type="link"
              className="category-add-btn btn-edit-save"
              onClick={() => {
                form.submit();
              }}
            >
              حفظ التعديلات
            </Button>
            <Popconfirm
              title="احذف المنتج ؟"
              description="هل انت متاكد من حذف المنتج ?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="نعم"
              cancelText="لا"
            >
              <Button type="text" className="btn-edit-delete" danger>
                حذف
              </Button>
            </Popconfirm>
          </div>
        </div>
        <div className="restaurant-add">
          <div className="product-add">
            <Form
              name="form_item_path"
              layout="vertical"
              onFinish={onFinish}
              form={form}
              initialValues={{
                RestaurantImages: giftImages,
                name,
                city,
                websiteURL,
                description,
                classification,
              }}
            >
              <div className="product-add-content">
                <div className="add-images">
                  <Form.Item
                    name="RestaurantImages"
                    label=""
                    rules={[
                      {
                        required: true,
                        message: 'يجب اضافة صور',
                      },
                    ]}
                  >
                    <Upload
                      name="RestaurantImages"
                      // listType="picture"
                      listType="picture-card"
                      className="image-uploader"
                      beforeUpload={beforeUpload}
                      customRequest={handleUpload}
                      // onPreview={() => setIsImageClicked(true)}
                      multiple
                      fileList={fileList}
                      accept="image/*"
                      onChange={({ fileList }) => {
                        console.log('fileList', fileList);
                        setFileList(fileList?.map((file) => file));
                        form.setFieldsValue({
                          RestaurantImages: fileList.map((file) => file),
                        });
                      }}
                      onRemove={handelRemove}
                    >
                      {uploadButton}
                    </Upload>
                  </Form.Item>
                </div>
                <div className="add-info">
                  <div className="add-info-form">
                    <h2 className="add-title">تفاصيل المطعم</h2>
                    <Form.Item
                      name="name"
                      label="اسم المطعم"
                      rules={[
                        {
                          required: true,
                          message: 'يجب  اضافة اسم المطعم',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="classification"
                      label="التصنيف"
                      rules={[
                        {
                          required: true,
                          message: '  يجب  اضافة التصنيف المطعم',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="city"
                      label="المدينة"
                      rules={[
                        {
                          required: true,
                          message: '  يجب  اضافة المدينة',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="websiteURL"
                      label="رابط الموقع"
                      rules={[
                        {
                          required: true,
                          message: 'يجب  اضافة رابط الموقع',
                        },
                      ]}
                    >
                      <Input type="url" />
                    </Form.Item>
                    <Form.Item
                      name="description"
                      label="وصف المطعم"
                      rules={[
                        {
                          required: true,
                          message: 'يجب  اضافة وصف المطعم',
                        },
                      ]}
                    >
                      <TextArea
                        style={{
                          width: '100%',
                          height: '155px',
                          padding: '10px',
                          resize: 'none',
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Flex
                justify="flex-end"
                className="submit-btn-wrapper"
                style={{ marginTop: '24px' }}
              >
                <Button
                  type="primary"
                  className="submit-btn-edit"
                  htmlType="submit"
                  loading={isLoadingEdit}
                >
                  حفظ التعديلات
                </Button>
              </Flex>
            </Form>
          </div>
        </div>
      </>
    </div>
  );
};

export default RestaurantEdit;
