import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import './ProductsDetails.scss';
import SectionHeader from '../SectionHeader/SectionHeader';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { Skeleton, Tag } from 'antd';
import { getProductDetails } from '../../network/products';
import { queryKeys } from '../../services/react-query/queryKeys';
import { DetailsProductLoading } from '../Loading/Loading';
const ProductsDetails = ({ category }) => {
  const [imgActive, setImgActive] = useState(0);
  const { Id } = useParams();
  const { IdDetailsFlower } = useParams();
  let flowerDetails;
  let productDetails;
  if (category === 'flower') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const flowerQuery = useQuery([queryKeys.flowerList, IdDetailsFlower], () =>
      getProductDetails(IdDetailsFlower)
    );
    flowerDetails = flowerQuery;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const productQuery = useQuery([queryKeys.productList, Id], () =>
      getProductDetails(Id)
    );
    productDetails = productQuery;
  }
  const { name, description, price, size, color, giftImages, giftCategories } =
    productDetails?.data?.model || flowerDetails?.data?.model || {};
  // console.log(data, "data details product");
  if (productDetails?.isLoading || flowerDetails?.isLoading) {
    return (
      <>
        <div className="product-details">
          <DetailsProductLoading />
        </div>
      </>
    );
  }
  return (
    <>
      <div className="product-details">
        <SectionHeader
          title={category === 'flower' ? 'باقات الورد' : 'المنتجات'}
          titlePath={category === 'flower' ? '/flowers' : '/products'}
          text="تعديل"
          textPath={
            category === 'flower'
              ? `/flowers/edit/${IdDetailsFlower}`
              : `/product/edit/${Id}`
          }
          icon={<EditOutlined />}
          subNextTitle={
            category === 'flower' ? 'تفاصيل باقة الورد' : 'تفاصيل المنتج'
          }
          subTitle={category === 'flower' ? 'باقات الورد' : 'المنتجات'}
        />
        <div className="product-details-card">
          <div className="product-details-imgs">
            <div className="active">
              <img src={giftImages?.[imgActive]?.imageURL} alt={name} />
            </div>
            <div className="details-img flex-wrap">
              {giftImages?.map((img, index) => (
                <div
                  key={index}
                  onClick={() => setImgActive(index)}
                  className={
                    index === imgActive
                      ? 'img-active image-upload'
                      : 'image-upload'
                  }
                >
                  <img
                    src={img?.imageURL}
                    alt={name}
                    className="ant-upload-list-item-image"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="product-details">
            <div className="details-header">
              <h3 className="details-header-title">{name}</h3>
              <span className="details-price">{price} رس</span>
            </div>
            <div className="details-info">
              <div className="card-info">
                <p className="card-info-title"> اللون: </p>
                <span className="card-info-desc">
                  {' '}
                  {color === 'undefined' ? 'غير محدد' : color}{' '}
                </span>
              </div>
              <div className="card-info">
                <p className="card-info-title"> المقاس: </p>
                <span className="card-info-desc">
                  {' '}
                  {size === 'undefined' ? 'غير محدد' : size}{' '}
                </span>
              </div>
              <div className="card-info-line">
                <p className="card-info-title"> الوصف: </p>
                <span className="card-info-desc"> {description} </span>
              </div>
              <div className="category">
                <h2 className="card-info-title"> الفئة </h2>
                <div className="category-btns">
                  {giftCategories?.map((category) => (
                    <Tag className="category-btn" key={category?.category?.id}>
                      {category?.category?.name}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductsDetails;
