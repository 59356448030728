import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import SectionHeader from "../SectionHeader/SectionHeader";
import ProductCard from "../productCard/ProductCard";
import SliderPage from "../SliderPage/SliderPage";
import { getFlowersList } from "../../network/flowers";
import { queryKeys } from "../../services/react-query/queryKeys";
import { PiFlowerTulip } from "react-icons/pi";
import { Button, Card, Skeleton } from "antd";
import { useNavigate } from "react-router";
import { HiQuestionMarkCircle } from "react-icons/hi2";
const Flowers = () => {
  const [pageCount,setPageCount] = useState(1)
  const navigate = useNavigate();
  console.log(pageCount);
  const { data , isLoading, isFetching } = useQuery(
    [queryKeys.flowerList, pageCount],
    () => getFlowersList(pageCount),{
      keepPreviousData: true,
    }
  );
  console.log(data);
  const { totalCount,currentPage,totalPages} = data?.metas?.pagination || {}
  return (
    <>
      <div className="flowers">
      <SectionHeader
        title="باقات الورد"
        titlePath="/flowers"
        text=" + اضافة باقة " 
        textPath="/add/flowers"
      />
        {data ? (
        <>
        {data?.model?.length > 0 ? (
        <div className="cards">
          {(data?.model || [1,2,3,4])?.map((product) => (
            <ProductCard key={product.id ? product.id : Math.random(product)} product={product} type="flowers" loading={isLoading || isFetching} />
          ))}
        </div>
        ) : (
          <>
          {isLoading || isFetching ? 
              (
              <div className="cards"> 
              {[1,2,3,4,5,6]?.map((product) => (
                <div className="card"
                key={product}
                >
                <Card
                hoverable
                loading
                cover={
                  (
                    <Skeleton.Image active style={{ width: "100% !important" }} />
                  )
                  }
                  />
                  </div>
              ))}
              </div>
              ): (
                
                <div className="no-products">
              <PiFlowerTulip size={150} color="#002554" />
              <h1 className="no-products-title" >لا يوجد باقات الورد</h1>
          <Button
            type="primary"
            onClick={() => {
              navigate("/add/flowers");
            }}
            >+اضافة باقة الورد</Button>
            </div>
            )
          }
          </>
        )
        }
        </>
      ) : (
        <>
          {isLoading || isFetching ? (
            <div className="cards">
              {[1, 2, 3, 4, 5, 6]?.map((product) => (
                <div className="card" key={product}>
                  <Card
                    hoverable
                    loading
                    cover={
                      <Skeleton.Image
                        active
                        style={{ width: "100% !important" }}
                      />
                    }
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="no-products">
              <HiQuestionMarkCircle size={150} color="#EB5959" /> 
              <p 
                style={{
                  textAlign: "center",
                  color: "#EB5959"
                }}
              >
                يوجد خطأ في الاتصال
              <br />
              الرجاء المحاولة مرة اخرى 
              </p>
            </div>
          )}
        </>
      )}
        <SliderPage
          totalCount={totalCount}
          totalPages={totalPages}
          setPageCount={setPageCount}
          pageCount={pageCount}
      /> 
      </div>
    </>
  );
};

export default Flowers;
