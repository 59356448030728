import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'user',
  initialState: {
    data: null,
    packge: null,
    addressList: [],
  },
  reducers: {
    setUserData: (user, action) => {
      user.data = action.payload;
    },
    setUserAddressList: (user, action) => {
      user.addressList = action.payload;
    },
    setUserPackge: (user, action) => {
      user.packge = action.payload;
    },
  },
});

export const { setUserData, setUserAddressList, setUserPackge } = slice.actions;

export default slice.reducer;
