import { SmileOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import  { useEffect, useState } from "react";
function BtnEmoji({ onEmojiSelect, emojiEdit }) {
  const [emoji, setEmoji] = useState();
  
  useEffect(() => {
    if (emojiEdit && emojiEdit.emotion) {
      setEmoji(emojiEdit.emotion);
    } else {
      setEmoji(null);
    }
  }, [emojiEdit]);

  const getEmojiSelected = value => {
    let sym = value.unified.split('-');
    let codesArray = sym.map(el => '0x' + el);
    let emojiValue = String.fromCodePoint(...codesArray);
    setEmoji(emojiValue);
    onEmojiSelect(emojiValue);
  };

  return (
    <Popover
      overlayInnerStyle={{ padding: 0 }}
      trigger="click"
      content={
        <Picker data={data} onEmojiSelect={getEmojiSelected} theme="light" />
      }
    >
      <Button className='emoji-upload-btn' >
        {emoji ? emoji :
        <span className='emoji-upload-text' style={{color: "rgb(72 76 82 / 29%)"}}>
        اضافة الرمز التعبيري
        </span>
        }
        </Button>
    </Popover>
  );
}

export default BtnEmoji;

