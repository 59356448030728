import React, { useEffect } from 'react';
import {
  PiCalendarBlank,
  PiHexagon,
  PiCurrencyDollarSimpleBold,
} from 'react-icons/pi';
import { AiOutlineUpload } from 'react-icons/ai';
import { BsFillTagFill } from 'react-icons/bs';
import './RequestsDetailsInfo.scss';
import dayjs from 'dayjs';
const RequestsDetailsInfo = ({ orderDetails }) => {
  // console.log(orderDetails, "orderDetails info");
  const {
    id,
    orderNumber,
    customerName,
    customerPhoneNumber,
    phoneNumber,
    receiverName,
    deliveryDate,
    eventDate,
    orderStatus,
    creationDate,
    modificationDate,
    deliveryAddress,
  } = orderDetails?.model || {};
  // console.log(dataInfo, "dataInfo");
  let color = '';
  let background = '';
  let text = '';
  switch (orderStatus) {
    case 0:
    case '0':
      text = 'قيد المراجعة';
      background = '#EE75011A';
      color = '#EE7501';
      break;
    case 1:
      text = 'تمت المراجعة';
      background = '#40C1AC1A';
      color = '#167767';
      break;
    case 2:
      text = 'قيد الانتظار';
      background = '#EE75011A';
      color = '#EE7501';
      break;
    case 3:
      text = 'مدفوع';
      background = '#40C1AC1A';
      color = '#167767';
      break;
    case 4:
      text = 'مكتمل';
      background = '#40C1AC1A';
      color = '#167767';
      break;
    case 5:
      text = ' تم الالغاء بواسطة العميل ';
      background = '#EB59591A';
      color = '#EB5959';
      break;
    case 6:
      text = ' تم الإلغاء بواسطة المسؤل ';
      background = '#EB59591A';
      color = '#EB5959';
      break;
    case 7:
      text = ' لم أجد هدية مناسبة ';
      background = '#016EEE1A';
      color = '#016EEE';
      break;
    // case 8:
    //   text = 'لم يتم تخطيط الهدية';
    //   background = '#CCE4F7';
    //   color = '#2D8CF0';
    //   break;
    case 8:
      text = 'تم إضافة الهدية من الأدمن';
      background = '#E6F7FF';
      color = '#1890FF';
      break;
    default:
      break;
  }
  const isPaid = () => {
    if (text == 'مدفوع' || text == 'مكتمل') {
      return 'مدفوع';
    } else {
      return 'غير مدفوع';
    }
  };
  // console.log(isPaid(), "isPaid");
  const dataInfo = {
    key: id,
    number: orderNumber || 'لا يوجد',
    phone: customerPhoneNumber || 'لا يوجد',
    name: customerName || 'لا يوجد',
    deliveryDate: dayjs(deliveryDate).format('YYYY-MM-DD') || 'لا يوجد',
    eventDate: dayjs(eventDate).format('YYYY-MM-DD') || 'لا يوجد',
    creationDate: dayjs(creationDate).format('YYYY-MM-DD'),
    payment: 'اقتراح هدية',
    order: isPaid() || 'لا يوجد',
    hashtag: 'لا يوجد',
    serves: deliveryAddress || 'لا يوجد',
  };
  return (
    <div className="requests-detiles-info">
      <div className="requests-detiles-info-card">
        <h3 className="requests-detiles-info-card-title">
          طلب رقم:{' '}
          <span>{dataInfo?.number === 'لا يوجد' ? '0' : dataInfo?.number}</span>
        </h3>
        <div className="requests-detiles-info-card-data">
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <PiCalendarBlank className="data-item-icon" /> تاريخ المناسبه{' '}
            </h4>
            <p className="data-item-text">
              {/* {dataInfo?.history} */}
              {dataInfo?.eventDate}
            </p>
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <AiOutlineUpload className="data-item-icon" /> تاريخ رفع الطلب{' '}
            </h4>
            <p className="data-item-text">{dataInfo?.creationDate}</p>
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <PiHexagon className="data-item-icon" /> اسم المناسبه{' '}
            </h4>
            <p className="data-item-text">{dataInfo?.serves}</p>
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <PiCurrencyDollarSimpleBold className="data-item-icon" /> الدفع{' '}
            </h4>
            <p className="data-item-text">{dataInfo?.order}</p>
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <BsFillTagFill className="data-item-icon" /> الوسم{' '}
            </h4>
            <p className="data-item-text">{dataInfo?.hashtag}</p>
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h3 className="data-item-title">حالة الطلب</h3>
            <p className="data-item-type" style={{ background, color }}>
              {text}
            </p>
          </div>
        </div>
      </div>
      <div className="requests-detiles-info-client">
        <h3 className="requests-detiles-info-client-title">تفاصيل العميل</h3>
        <div className="requests-detiles-info-client-data">
          <div className="requests-detiles-info-client-data-item">
            <h4 className="data-item-title">الاسم:</h4>
            <p className="data-item-text">{dataInfo?.name}</p>
          </div>
          <div className="requests-detiles-info-client-data-item">
            <h4 className="data-item-title">رقم الجوال:</h4>
            <p className="data-item-text"> {dataInfo?.phone} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestsDetailsInfo;
