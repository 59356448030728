import "./ProductCard.scss";
import { Button, Card, Skeleton, Tag } from "antd";
import { Link } from "react-router-dom";
const { Meta } = Card;
export default function ProductCard({ product, type, loading }) {
  const {
    city,
    classification,
    description,
    giftImages,
    name,
    id,
    price,
    title,
    size,
    color,
    giftCategories,
  } = product;
  return (
    <>
      <div className="card">
        {type === "products" || type === "flowers" ? (
          <> 
            <Link to={loading ? ""  : type === "products" ? `/products/details/${id}` : `/flowers/details/${id}`}>
              <Card
                hoverable
                loading={loading}
                cover={
                  loading ? (
                    <Skeleton.Image active style={{ width: "100% !important" }} />
                  ) : (
                    <div className="card-img" >
                      <img alt={title} src={giftImages?.[0]?.imageURL} />
                    </div>
                  )
                }
              >
                <Meta
                  title={
                    <div className="card-header">
                      <h3 className="card-header-title">{name}</h3>
                      <span className="card-price">{price} رس</span>
                    </div>
                  }
                />
                <div className="card-items">
                  <div className="card-info">
                    <p className="card-info-title"> اللون: </p>
                    <span className="card-info-desc"> {color === "undefined" ? "غير محدد" : color} </span>
                  </div>
                  <div className="card-info">
                    <p className="card-info-title"> المقاس: </p>
                    <span className="card-info-desc"> {size === "undefined" ? "غير محدد" : size } </span>
                  </div>
                  <div className="card-info-line">
                    <p className="card-info-title"> الوصف: </p>
                    <span className="card-info-desc">
                      {" "}
                      {description?.slice(1, 40)}...{" "}
                    </span>
                  </div>
                  <div className="category">
                    <h2 className="card-info-title"> الفئة </h2>
                    <div className="category-btns">
                      {giftCategories?.map((category) => (
                        <Tag
                        key={category?.category?.id}
                        className="category-btn"
                        >
                          {category?.category?.name}
                        </Tag>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
            </Link>
          </>
        ) : (
          <>
            <Link to={ loading ? "" : `/restaurant/details/${id}`}>
              <Card
                loading={loading}
                hoverable
                cover={
                  loading ? (
                    <Skeleton.Image active style={{ width: "100% !important" }} />
                  ) : (
                  <div className="card-img"  >
                    <img alt={name} src={giftImages?.[0]?.imageURL}  />
                  </div>
                  )
                }
              >
                <Meta
                  title={
                    <div className="card-header">
                      <h3 className="card-header-title">{name}</h3>
                    </div>
                  }
                />
                <div className="card-items">
                  <div className="card-info">
                    <p className="card-info-title"> التنصيف: </p>
                    <span className="card-info-desc"> {classification} </span>
                  </div>
                  <div className="card-info">
                    <p className="card-info-title"> المدينة: </p>
                    <span className="card-info-desc"> {city} </span>
                  </div>
                  <div className="card-info-line">
                    <p className="card-info-title"> الوصف: </p>
                    <span className="card-info-desc">
                      {" "}
                      {description?.slice(1, 40)}...{" "}
                    </span>
                  </div>
                </div>
              </Card>
            </Link>
          </>
        )}
      </div>
    </>
  );
}
