import React, { useEffect, useState } from 'react'
import { RiDeleteBinLine } from "react-icons/ri";
import {  Button, Modal, message } from 'antd';
import "./CardImageDelete.scss"
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteImage } from '../../../network/images';
import { queryKeys } from '../../../services/react-query/queryKeys';
const CardImageDelete = ({ idDelete,setIdDelete }) => {
    // console.log(idDelete, "idDelete");
    const client = useQueryClient();
    const [modalVisible, setModalVisible] = useState(false);
    const handleRemove =  useMutation(
        () => deleteImage(idDelete),
        {
          onSuccess: (res) => {
            console.log("res", res);
            if(res){
            message.success("تم حذف الصورة بنجاح");
            client.invalidateQueries([queryKeys.imageList]);
            setIdDelete(null);
            }else{
                message.error("حدث خطأ ما");
                setIdDelete(null);
            }
          },
          onError: () => {
            message.error("حدث خطأ ما");
            setIdDelete(null);
          },
      });
    const handleCancel = () => {
        setModalVisible(false);
        setIdDelete(null);
        message.error("لم يتم حذف الصورة");
    }
    const handleFinish = () => {
        setModalVisible(false);
        handleRemove.mutate();
    }
  return (
    <>
        <div className="card-image-delete">
            <div className="card-image-delete-icon"
                onClick={() => {
                    setModalVisible(true);
                }}
            >
                <RiDeleteBinLine />
            </div>
            <Modal 
                open={modalVisible}
                onCancel={handleCancel}
                footer={null}
                closeIcon={false}
                className='card-image-delete-modal-container'
            >
                <div className="card-image-delete-modal">
                    <h3 className='card-image-delete-modal-title'>هل انت متأكد؟</h3>
                    <p className='card-image-delete-modal-text'>هل انت متأكد من رغبتك في حذف الصورة؟</p>
                    <div className="card-image-delete-modal-btns">
                        <Button type="danger"
                            onClick={handleFinish}
                            className='card-image-delete-modal-btn'
                        >نعم ، احذف الصورة</Button>
                        <Button type='text'
                            onClick={handleCancel}
                            className='card-image-delete-modal-btn-cancel'
                        >الغاء</Button>
                    </div>
                </div>
            </Modal>
        </div>
    </>
  )
}

export default CardImageDelete