import { Button, Input, Select, Space, Spin, Table, Tag, message } from 'antd';
import {useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { getAllEvents } from '../../network/allEvents';
import { queryKeys } from '../../services/react-query/queryKeys';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
const AllEvents = () => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const {
    data: orders,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [queryKeys.allEvents, pageCount],
    queryFn: () => {
      return getAllEvents({ pageCount });
    },
    keepPreviousData: true,
  });
  const { totalCount, currentPage, totalPages } =
    orders?.metas?.pagination || {};
  const columns = [
    {
      title: 'رقم الطلب',
      dataIndex: 'number',
      render: (text) => {
        return (
          <span className="color-blu">{text === 'لا يوجد' ? '0' : text}</span>
        );
      },
    },
    {
      title: 'العميل',
      dataIndex: 'name',
      filteredValue: [searchText],
      onFilter: (value, record) => {
        const name = String(record.name).toLowerCase();
        const number = String(record.number).toLowerCase();
        const searchValue = value.toLowerCase();
        // console.log(record);
        return name.includes(searchValue) || number.includes(searchValue);
      },
      render: (text) => <span className="color-blu"> {text} </span>,
    },
    {
      title: 'تاريخ الطلب',
      dataIndex: 'History',
    },
    {
      title: 'الدفع',
      dataIndex: 'payment',
      render: (text) => {
        const spanStyle = {
          fontSize: '13px',
          fontWeight: '400',
          color: '#002554',
        };
        let paied = '';
        switch (text) {
          case true:
            paied = 'مدفوع';
            break;
          case false:
            paied = 'غير مدفوع';
            break;
          default:
            paied = 'غير مدفوع';
            break;
        }
        return <span style={spanStyle}>{paied}</span>;
      },
    },
    {
      title: 'حالة الطلب',
      dataIndex: 'order',
      render: (order) => {
        // console.log(order,"orderStatus");
        let color = '';
        let background = '';
        let text = '';

        switch (order) {
          case 0:
          case '0':
            text = 'قيد المراجعة';
            background = '#EE75011A';
            color = '#EE7501';
            break;
          case 1:
            text = 'تمت المراجعة';
            background = '#40C1AC1A';
            color = '#167767';
            break;
          case 2:
            text = 'قيد الانتظار';
            background = '#EE75011A';
            color = '#EE7501';
            break;
          case 3:
            text = 'مدفوع';
            background = '#40C1AC1A';
            color = '#167767';
            break;
          case 4:
            text = 'مكتمل';
            // text = "تم التوصيل"
            background = '#40C1AC1A';
            color = '#167767';
            break;
          case 5:
            text = 'تم الالغاء بواسطة العميل ';
            background = '#EB59591A';
            color = '#EB5959';
            break;

          case 6:
            text = 'تم الإلغاء بواسطة المسؤل ';
            background = '#EB59591A';
            color = '#EB5959';
            break;

          case 7:
            text = 'لم أجد هدية مناسبة ';
            background = '#016EEE1A';
            color = '#016EEE';
            break;
          case 8:
            text = 'لم يتم تخطيط الهدية';
            background = '#CCE4F7';  
            color = '#2D8CF0';   
            break;
          default:
            break;
        }

        return (
          <Tag
            style={{
              color: color,
              background: background,
              padding: '7px 12px 8px 12px',
            }}
            className=" w-full flex justify-center items-center border-none rounded-md "
          >
            {order && text === 0 ? 'قيد المراجعة' : text}
          </Tag>
        );
      },
    },
    {
      title: 'الوسم',
      dataIndex: 'hashtag',
      render: (text) => <span className="highlight">{text}</span>,
    },
    {
      title: 'القيمة',
      dataIndex: 'value',
      render: (text) => (
        <span
          style={{
            color: '#002554',
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          SR {text}
        </span>
      ),
    },
  ];
  const dataOrder = orders?.model?.map((order) => {
    return {
      key: order?.id,
      id: order?.id,
      number: order?.orderNumber || 'لا يوجد',
      name: order?.userName || 'لا يوجد',
      History: dayjs(order?.creationDate).format('YYYY-MM-DD'),
      payment: order?.isPaied,
      order: 8,
      hashtag: order?.tag || 'لا يوجد',
      value: order?.total || 'لا يوجد',
    };
  });
  const handlePageChange = (pageNumber) => {
    setPageCount(pageNumber);
    client.invalidateQueries([queryKeys.order, pageNumber]);
  };
  const handleNextClick = () => {
    if (pageCount < totalPages) {
      setPageCount(pageCount + 1);
    }
  };

  const handlePrevClick = () => {
    if (pageCount > 1) {
      setPageCount(pageCount - 1);
    }
  };
  return (
    <>
      <SectionHeader title="جميع المناسبات" titlePath="/all-events" />
      <div className="requests position-relative">
        <div
          className="filter-search-container"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Input.Search
            placeholder="البحث بـ الاسم ، رقم الطلب"
            className="request-search"
            onSearch={(value) => {
              setSearchText(value);
            }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          {/* <div
            className=""
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '24px',
            }}
          >
            <Select
              className="requests-select"
              popupMatchSelectWidth={false}
              placeholder="حالة الطلب"
              onChange={(value) => {
                setSelectTypeOrder(value);
              }}
              allowClear
              style={{ width: '200px', height: '55px' }}
              options={[
                { value: null, label: 'الكل' },
                { value: "0", label: 'قيد المراجعة' },
                { value: 1, label: 'تمت المراجعة' },
                { value: 2, label: 'قيد الانتظار' },
                { value: 3, label: 'مدفوع' },
                { value: 4, label: 'مكتملة' },
                { value: 5, label: 'تم الالغاء بواسطة العميل' },
                { value: 6, label: 'تم الالغاء بواسطة المسؤل' },
                { value: 7, label: 'لم أجد هدية مناسبة' },
                { value: 8, label: 'لم يتم تخطيط الهدية' },
              ]}
            />
            <Select
              className="requests-select"
              popupMatchSelectWidth={false}
              placeholder="حالة الدفع"
              onChange={(value) => {
                // console.log('valuedddddddddd', value);
                setSelectTypePayment(value);
              }}
              allowClear
              style={{ width: '200px', height: '55px' }}
              options={[
                { value: null, label: 'الكل' },
                { value: "paid", label: 'مدفوع' },
                { value: "unPaid", label: 'غير مدفوع' },
              ]}
            />
          </div> */}
        </div>
        <Table
          columns={columns}
          dataSource={dataOrder}
          className="position-relative"
          loading={isLoading || isFetching}
          pagination={{
            current: pageCount,
            total: totalCount,
            pageSize: 10,
            responsive: true,
            showTotal: (total, range) => {
              return `عرض ${pageCount} من اصل ${totalPages || 1} `;
            },
            showSizeChanger: false,
            itemRender: (current, type, originalElement) => {
              if (type === 'prev') {
                return (
                  <>
                    <div onClick={handlePrevClick}>
                      <RightOutlined />
                      <span>السابق</span>
                    </div>
                  </>
                );
              }
              if (type === 'next') {
                return (
                  <>
                    <div onClick={handleNextClick}>
                      <span> التالي</span>
                      <LeftOutlined />
                    </div>
                  </>
                );
              }
              return originalElement;
            },
            itemActiveBg: '#6D29F6',
            onChange: handlePageChange,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                // console.log(record,"eventevent");
                
                const { id } = record;
                const url = `/all-events/details/${id}`;
                navigate(url);
              },
            };
          }}
          scroll={{ x: 'max-content' }}
          rowKey={(record) => record.key}
        />
      </div>
    </>
  );
};

export default AllEvents;
