import { axiosInstance } from '../services/axiosInstance';
import catchAsync from '../utils/catchAsync';

export const getProductsList = catchAsync(async (pageCount) => {
  const { data } = await axiosInstance.get(
    `/Product?Page=${pageCount}&Size=10&ProductType=0`
  );
  return data;
});
export const getProductDetails = catchAsync(async (Id) => {
  const { data } = await axiosInstance.get(`/Product/${Id}`);
  return data;
});
export const addProducts = catchAsync(async (body) => {
  const { data } = await axiosInstance.post('/Product', body);
  return data;
});
export const editProducts = catchAsync(async (body) => {
  const { data } = await axiosInstance.put('/Product', body);
  return data;
});
export const editProductCoustomer = catchAsync(async (body) => {
  const { data } = await axiosInstance.put('/Product', body);
  return data;
});
export const deleteImage = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(`/Image/${id}`);
  return data;
});
