import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";


export const getSettings = catchAsync(async () => {
  const { data } = await axiosInstance.get(`/Settings`);
  return data;
});
export const UpdateSettings = catchAsync(async (body) => {
  const { data } = await axiosInstance.put('/Settings', body);
  return data;
});