import { Pagination } from 'antd';
import "./SliderPage.scss";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const SliderPage = ({ totalCount, setPageCount, totalPages, pageCount }) => {
  const handlePageChange = (pageNumber) => {
    setPageCount(pageNumber);
  };

  const handleNextClick = () => {
    if (pageCount < totalPages) {
      setPageCount(pageCount + 1);
    }
  };

  const handlePrevClick = () => {
    if (pageCount > 1) {
      setPageCount(pageCount - 1);
    }
  };

  return (
    <div className="slider-page">
      <div className="itemRender">
        <Pagination
          responsive={true}
          current={pageCount}
          total={totalCount}
          pageSize={10}
          showTotal={(total, range) => {
            return `عرض ${pageCount} من اصل ${totalPages || 1} `;
          }}
          showSizeChanger={false}
          defaultPageSize={10}
          itemRender={(current, type, originalElement) => {
            if (type === "prev") {
              return (
                <>
                  <div onClick={handlePrevClick}>
                    <RightOutlined />
                    <span style={{ margin: " 0 10px 0 23px" }}>السابق</span>
                  </div>
                </>
              );
            }
            if (type === "next") {
              return (
                <>
                  <div onClick={handleNextClick}>
                    <span style={{ margin: " 0 23px 0 10px" }}> التالي</span>
                    <LeftOutlined />
                  </div>
                </>
              );
            }
            return originalElement;
          }}
          itemActiveBg="#6D29F6"
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default SliderPage;
