import { Link } from "react-router-dom";
import "./SectionHeader.scss";
import { LeftOutlined } from "@ant-design/icons";
const SectionHeader = ({
  title,
  titlePath,
  textPath,
  text,
  remove,
  icon,
  subTitle,
  subNextTitle,
}) => {
  return (
    <>
      <div className="header-product">
        <Link className="header-title" to={titlePath}>
          {title}
        </Link>
        <div className="header-path">
            <Link className="text-path" to={textPath}>
              {icon}
              {text}
            </Link>
        </div>
      </div>
      {subTitle || subNextTitle ? (
        <div className="sub-title-page">
          <p>
            <span> {subTitle} </span> <LeftOutlined />{" "}
            <span> {subNextTitle} </span>
          </p>
        </div>
      ) : null}
    </>
  );
};
export default SectionHeader;
