import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";

export const getOrdersDetails = catchAsync(async (IdRequest) => {
  const { data } = await axiosInstance.get(`/Order/${IdRequest}`);
  return data;
});

export const addOrder = catchAsync(async ({body,pageCount}) => {
  const { data } = await axiosInstance.post(`/Order/GetAdminOrders?page=${pageCount }&size=10`, body);
  return data;
});

export const updateOrder = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/order/UpdateStatus`, body);
  return data;
});
export const updatePrice = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/order/UpdateItemPrice`, body);
  return data;
});

