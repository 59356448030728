import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Card, Flex, Form, Input, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { getSettings, UpdateSettings } from '../../network/servicesPrices';
import { queryKeys } from '../../services/react-query/queryKeys';

const ServicesPrices = () => {
  const [form] = Form.useForm();
  const client = useQueryClient();
  const [loadingAdd, setLoadingAdd] = useState(false);
  const { mutate: onAddSubscriptionAmount } = useMutation({
    mutationKey: ['addUser'],
    mutationFn: (body) => UpdateSettings(body),
    onSuccess: (data) => {
      setLoadingAdd(false);
      if (data?.message?.status === 500) {
        message.error(data?.message);
        return false;
      }
      message.success('تم حفظ التعديلات بنجاح');
      client.invalidateQueries({ queryKey: [queryKeys.settings] });
    },
    onError: (error) => {
      setLoadingAdd(false);
      console.log('error:', error);
    },
  });
  const {data: settings,isLoading} = useQuery({
    queryKey: [queryKeys.settings],
    queryFn: () => getSettings(),
    keepPreviousData: true
  })
const taxPercentage = Number(
  settings?.model?.find((item) => item?.settingKey === "TaxPercentage")?.settingValue || 0
);
const subscription = settings?.model?.find((item) => item?.settingKey === "SubscriptionAmount")
// console.log(subscription,"subscription");

const subscriptionAmount = Number(subscription?.settingValue || 0);

const taxAmount = subscriptionAmount * taxPercentage;
const totalAmount = subscriptionAmount + taxAmount;
  useEffect(() => {
    form.setFieldsValue({
      price: subscriptionAmount || 0,
    });
  }, [settings]);
  if(isLoading){
    return (
      <div className=""
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <Spin size="large" />
      </div>
    )
  }
  const onFinish = (values) => {
    const body = {
      Id: subscription?.id,
      SettingValue: values.price
  }
  onAddSubscriptionAmount(body);
  };
  return (
    <>
      <div
        className=""
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Card
          title={
            <Flex
              justify="space-between"
              align="center"
              style={{ width: '100%',flexWrap:"wrap" }}
              className='py-2'
            >
              <h1 
                className='text-2xl font-bold'
              >
                سعر الخدمة
              </h1>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingAdd}
                onClick={() => form.submit()}
              >
                حفظ التعديلات
              </Button>
            </Flex>
          }
          style={{
            width: '100%',
          }}
        >
          <Form
            name="add-services-prices"
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="price"
              label="ادخل السعر"
              rules={[
                {
                  required: true,
                },
              ]}
              style={{
                maxWidth: '400px',
              }}
            >
              <Input placeholder="ادخل السعر" type="number" min={0} />
            </Form.Item>
          </Form>
          <div
            // style={{
            //   display: 'flex',
            //   alignItems: 'center',
            //   gap: '10px',
            // }}
            className='flex items-center gap-2 flex-col md:flex-row'
          >
            <h1
              style={{
                // color: '#6D29F6',
                // fontSize: '25px',
                // fontWeight: '700',
              }}
              className='text-[20px] md:text-[25px] leading-3 text-[#6D29F6] font-bold'
            >
              الاجمالي مع الضريبه :
            </h1>
            <p 
              className=' text-[18px] md:text-[20px] leading-3 font-bold'
            >
              {totalAmount} ر.س{' '}
            </p>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ServicesPrices;