import { useQuery } from '@tanstack/react-query'
import SectionHeader from '../SectionHeader/SectionHeader'
import ProductCard from '../productCard/ProductCard'
import SliderPage from '../SliderPage/SliderPage'
import { queryKeys } from '../../services/react-query/queryKeys'
import { getRestaurantsList } from '../../network/restaurants'
import { useState } from 'react'
import { Button, Card, Skeleton } from 'antd'
import { useNavigate } from 'react-router'
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { HiQuestionMarkCircle } from "react-icons/hi2";
const Restaurant = () => {
  const [pageCount,setPageCount] = useState( 1)
  const navigate = useNavigate();
  console.log(pageCount);
  const { data, isLoading,isFetching } = useQuery(
    [queryKeys.restaurantList,pageCount],
    () => getRestaurantsList(pageCount),{
      keepPreviousData: true,
    }
  );
  const { totalCount,currentPage,totalPages} = data?.metas?.pagination || {}
      console.log(data);
  return (
    <>
        <SectionHeader title="المطاعم" titlePath="/restaurant" text=" + اضافة مطعم" textPath="/add/restaurant"    />
        <div className="restaurant">
        {data ? (
        <>
        {data?.model?.length > 0 ? (
        <div className="cards">
          {(data?.model || [1,2,3,4])?.map((product) => (
            <ProductCard key={product.id ? product.id : Math.random(product)} product={product} type="restaurant" loading={isLoading || isFetching} />
          ))}
        </div>
        ) : (
          <>
          {isLoading || isFetching ? 
              (
              <div className="cards"> 
              {[1,2,3,4,5,6]?.map((product) => (
                <div className="card"
                key={product}
                >
                <Card
                hoverable
                loading
                cover={
                  (
                    <Skeleton.Image active style={{ width: "100% !important" }} />
                  )
                  }
                  />
                  </div>
              ))}
              </div>
              ) : (
              <div className="no-products">
                  <MdOutlineRestaurantMenu size={150} color="#002554" />
                  <h1 className="no-products-title" >لا يوجد مطاعم</h1>
              <Button
                type="primary"
                onClick={() => {
                  navigate("/add/restaurant");
                }}
                >+اضافة مطعم</Button>
                </div>
                
              )
            }
            </>
        )
        }
        </>
      ) : (
        <>
          {isLoading || isFetching ? (
            <div className="cards">
              {[1, 2, 3, 4, 5, 6]?.map((product) => (
                <div className="card" key={product}>
                  <Card
                    hoverable
                    loading
                    cover={
                      <Skeleton.Image
                        active
                        style={{ width: "100% !important" }}
                      />
                    }
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="no-products">
              <HiQuestionMarkCircle size={150} color="#EB5959" /> 
              <p 
                style={{
                  textAlign: "center",
                  color: "#EB5959"
                }}
              >
                يوجد خطأ في الاتصال
              <br />
              الرجاء المحاولة مرة اخرى 
              </p>
            </div>
          )}
        </>
      )}
        <SliderPage
          totalCount={totalCount}
          totalPages={totalPages}
          setPageCount={setPageCount}
          pageCount={pageCount}
      /> 
        </div>
    </>
  )
}

export default Restaurant