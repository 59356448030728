import { useMemo, useState, useCallback, useEffect } from "react";
import { Button, Dropdown, Popconfirm, Skeleton, Spin, Table, message } from 'antd';
import { PiDotsThreeVertical } from "react-icons/pi";
import "./EventList.scss";
import AddEvent from './AddEvents/AddEvent';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from "../../services/react-query/queryKeys";
import { deleteEvent, getEventsList } from "../../network/eventList";
import dayjs from "dayjs";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const EventList = () => {
  const client = useQueryClient();
  const [idSelect, setIdSelect] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [editItem, setEditItem] = useState();

  // Start fetch data
  const { data, isLoading, isFetching } = useQuery(
    [queryKeys.eventList, pageCount],
    () => getEventsList(pageCount), {
      keepPreviousData: true,
  }
  );
  console.log(data);
  const { totalCount, currentPage, totalPages } = data?.metas?.pagination || {};
  // End fetch data

  // Start select edit data
  const editSelectItem = () => {
    const editItemSelect  = data?.model?.find((event) => event?.id === idSelect);
    setEditItem(editItemSelect);
  }

  // End select edit data

  // Start delete event
  const handleRemove = useMutation(() => deleteEvent(idSelect), {
    onSuccess: (res) => {
      if (res) {
        setPageCount(1)
        client.invalidateQueries([queryKeys.eventList]);
        message.success("تم حذف المناسبة بنجاح");
        setIdSelect(null);
        setEditItem(null);
      } else {
        message.error("حدث خطأ ما");
      }
    },
    onError: (error) => {
      console.log('error :', error);
      message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
      setIdSelect(null);
      setEditItem(null);
    },
  });
  // End delete event

  const confirm = (e) => {
    editSelectItem();
    handleRemove.mutate();
  };
  const cancel = (e) => {
    message.error('لم يتم الحذف');
  };


  const [openModal, setOpenModal] = useState(false);
  // Start edit and delete Buttons
  const items = [
    {
      key: '1',
      label: (
        <Popconfirm
          title="احذف المنتج ؟"
          description="هل انت متاكد من حذف المنتج ؟"
          onConfirm={confirm}
          onCancel={cancel}
          okText="نعم"
          cancelText="لا"
        >
          <Button type="text" className='btn-edit-delete' danger>
            حذف
          </Button>
        </Popconfirm>
      ),
    },
    {
      key: '2',
      label:  (
        <Button
          type="link"
          className='btn-edit'
          
          onClick={() => {
            setOpenModal(true);
            editSelectItem();
          }}
        >
          تعديل
        </Button>
      ),
    },
  ];
  // End edit and delete Buttons
    // Start header table
  const columns = [
    {
      title: 'الرمز التعبيري',
      dataIndex: 'emoji',
      render: (text) => {
        // return <img src={text} style={{width:"36px",height:"36px"}}  alt="" />
        return <span 
        // data-emoji={`${text}/`}
        className="emoji-img" >
          {text ? text : "لا يوجد"}
        </span>
      }
    },
    {
      title: 'المناسبة',
      dataIndex: 'event',
      render: (text) => <span className='color-blu'> {text ? text : "لا يوجد"} </span>
    },
    {
      title: 'التاريخ',
      dataIndex: 'History',
    },
    {
      title: 'الوسم',
      dataIndex: 'hashtag',
      render: (text) => <span className="highlight">{text ? text : "لا يوجد" } </span>
    },
    {
      title: 'اجراءات',
      dataIndex: 'execution',
      render: (text) => <span style={{ color: "#000",width: "32px",height: "32px", textAlign:"end"}}>{text}</span>,
      className:"ant-table-cell-execution",
    }
  ];
  // End header table
  // Start table Values
  const dataEventList = data?.model?.map((event) => {
    return {
      key: event?.id,
      emoji: event?.emotion,
      event: event?.name,
      // History: event?.eventDate ,
      History: dayjs(event?.eventDate).format('YYYY-MM-DD'),
      hashtag: event?.tagName,
      execution: (
        <>
          <Dropdown
            placement="bottom"
            onOpenChange={(open) => {
              console.log(open, "open-event");
              if (open) {
                setIdSelect(event?.id);
              } else {
                setIdSelect();
              }
            }}
            menu={{
              items,
            }}
            trigger={['click']}
          >
            <PiDotsThreeVertical />
          </Dropdown>
        </>
      ),
    };
  });
  // End table Values
  const handlePageChange = (pageNumber) => {
    setPageCount(pageNumber);
    client.invalidateQueries([queryKeys.eventList, pageNumber]);
  };
  const handleNextClick = () => {
    if (pageCount < totalPages) {
      setPageCount(pageCount + 1);
    }
  };

  const handlePrevClick = () => {
    if (pageCount > 1) {
      setPageCount(pageCount - 1);
    }
  };
  return (
    <>
      <AddEvent setEditItem={setEditItem} editItem={editItem} setOpenModal={setOpenModal} openModal={openModal}  />
      <div className="events-list position-relative ">
        <Table
          columns={columns}
          dataSource={dataEventList}
          className='position-relative'
          loading={isLoading || isFetching}
          // pagination={false}
          pagination={{
            current: pageCount,
            total: totalCount,
            pageSize: 10,
            responsive: true,
            showTotal:(total, range) => {
              return `عرض ${pageCount} من اصل ${totalPages || 1} `;
            },
            showSizeChanger: false,
            itemRender:(current, type, originalElement) => {
              if (type === "prev") {
                return (
                  <>
                    <div onClick={handlePrevClick}>
                      <RightOutlined />
                      <span>السابق</span>
                    </div>
                  </>
                );
              }
              if (type === "next") {
                return (
                  <>
                    <div onClick={handleNextClick}>
                      <span> التالي</span>
                      <LeftOutlined />
                    </div>
                  </>
                );
              } 
              return originalElement;
            },
            itemActiveBg: "#6D29F6",
            onChange: handlePageChange,
            
          }
        }
        scroll={{ x: "max-content"}}
        />
      </div>
    </>
  );
};

export default EventList;
