// import firebase from "firebase/app";
// import firebase from "firebase"sss
// import firebase from 'firebase/compat/app';
import { notification } from 'antd';
import { initializeApp } from 'firebase/app';

import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { FaBell } from 'react-icons/fa';
const firebaseConfig = {
  apiKey: 'AIzaSyD5B7s3cVOY6NexgsREgp2ySUQ8wNOIWzQ',
  authDomain: 'joymakers-6c5b2.firebaseapp.com',
  projectId: 'joymakers-6c5b2',
  storageBucket: 'joymakers-6c5b2.appspot.com',
  messagingSenderId: '450109502155',
  appId: '1:450109502155:web:43615c8c7544058722614e',
  measurementId: 'G-018MCXL885',
};

initializeApp(firebaseConfig);
const messaging = getMessaging();
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BI3buDtpTVFdkfRtKSnpPzTQjWZBx67VWgnkL-XqvJVTxExhqG-sojJxfh64Ea_ABl0aENamvnQ-JKhNKn2qaD8',
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
        return currentToken;
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.'
        );
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = (onSuccessfcm) =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(messaging, 'messagingmessagingmessaging');
      // console.log("onMessageListener", payload)
      // 1 orders
      // 2 comments
      // ReferenceId
      notification.info({
        message: payload?.data?.NameLT,
        description: payload?.data?.DescriptionLT,
        icon: <FaBell style={{ color: '#6D29F6' }} />,
      });
      onSuccessfcm(payload);
    });
  });
