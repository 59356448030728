import React, { useState } from 'react';
import './ImagePage.scss';
import SliderPage from '../SliderPage/SliderPage';
import CardImage from './CardImage/CardImage';
import CardImageEdit from './CardImageEdit/CardImageEdit';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { getImageList } from '../../network/images';
import { Button } from 'antd';
import { IoMdImages } from 'react-icons/io';
import { ImagesPageLoading } from '../Loading/Loading';
import { HiQuestionMarkCircle } from 'react-icons/hi2';
const ImagePage = () => {
  const [pageCount, setPageCount] = useState(1);
  // console.log(pageCount);
  const { data, isLoading, isFetching } = useQuery(
    [queryKeys.imageList, pageCount],
    () => getImageList(pageCount),
    {
      keepPreviousData: true,
    }
  );
  console.log(data);
  const { totalCount, currentPage, totalPages } = data?.metas?.pagination || {};

  return (
    <>
      <div className="image-page-container">
        <div className="header-product">
          <div className="header-title">صور النظام</div>
          <div className="header-path">
            <div className="text-path">
              <CardImageEdit category="add" />
            </div>
          </div>
        </div>
        {data ? (
          <>
            {data?.model?.length > 0 ? (
              <div className="image-page-cards">
                {(data?.model || [1, 2, 3, 4, 5, 6])?.map((item, index) => {
                  const { imagePath, id } = item || {};
                  return (
                    <CardImage
                      loading={isLoading || isFetching}
                      key={index}
                      number={index}
                      image={imagePath}
                      id={id}
                      data={data}
                    />
                  );
                })}
              </div>
            ) : (
              <>
                {isLoading || isFetching ? (
                  <div className="cards">
                    {[1, 2, 3, 4, 5, 6]?.map((product) => (
                      <div key={product}>
                        <ImagesPageLoading />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-products">
                    <IoMdImages size={150} color="#002554" />
                    <h1 className="no-products-title">لا يوجد صور للنظام</h1>
                    <Button type="primary">
                      <CardImageEdit category="add" />
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {isLoading || isFetching ? (
              <div className="cards">
                {[1, 2, 3, 4, 5, 6]?.map((product) => (
                  <div key={product}>
                    <ImagesPageLoading />
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-products">
                <HiQuestionMarkCircle size={150} color="#EB5959" />
                <p
                  style={{
                    textAlign: 'center',
                    color: '#EB5959',
                  }}
                >
                  يوجد خطأ في معالجة البيانات
                  <br />
                  الرجاء المحاولة مرة اخرى
                </p>
              </div>
            )}
          </>
        )}
        <SliderPage
          totalCount={totalCount}
          totalPages={totalPages}
          setPageCount={setPageCount}
          pageCount={pageCount}
        />
      </div>
    </>
  );
};

export default ImagePage;
