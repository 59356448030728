import { useState, useEffect } from 'react';
import {
  Button,
  Popconfirm,
  message,
  Flex,
  Form,
  Input,
  Upload,
  InputNumber,
} from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './ProductsEdit.scss';
import { PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import AddCategory from '../AddCategory/AddCategory';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import {
  deleteImage,
  editProductCoustomer,
  editProducts,
  getProductDetails,
} from '../../network/products';
import { deleteProduct, editFlower } from '../../network/flowers';
import {
  CheckboxCategoryAge,
  CheckboxCategoryGender,
  CheckboxCategorys,
} from '../Category/Category';
import { EditProductLoading, SectionHeaderDetails } from '../Loading/Loading';
import { DeleteOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getOrdersDetails } from '../../network/order';

const ProductsEdit = ({ category }) => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const { IdEdit } = useParams();
  const { IdEditFlower } = useParams();
  const { IdOrder } = useParams();
  const [form] = Form.useForm();
  let flowerDetails;
  let productDetails;
  let orderCustomer;
  if (category === 'flower') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const flowerQuery = useQuery([queryKeys.flowerList, IdEditFlower], () =>
      getProductDetails(IdEditFlower)
    );
    flowerDetails = flowerQuery;
  }
  if (category === 'product') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const productQuery = useQuery([queryKeys?.productList, IdEdit], () =>
      getProductDetails(IdEdit)
    );
    productDetails = productQuery;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const orderDetails = useQuery([queryKeys.orderDetails], () =>
      getOrdersDetails(IdOrder)
    );
    orderCustomer = orderDetails;
  }
  console.log(orderCustomer, 'orderCustomer');
  const specialProducts = orderCustomer?.data?.model?.specialProducts.find(
    (item) => item.id === IdEdit
  );
  const removeImage = useMutation((id) => deleteImage(id), {
    onSuccess: (res) => {
      if (res) {
        message.success('تم الحذف الصورة بنجاح');
        client.invalidateQueries([queryKeys.productList]);
      } else {
        message.error('حدث خطأ ما');
      }
    },
    onError: () => {
      message.error('حدث خطأ ما');
    },
  });
  const { name, description, price, size, color, giftImages, giftCategories } =
    productDetails?.data?.model ||
    flowerDetails?.data?.model ||
    specialProducts ||
    {};
  const orderNumber = orderCustomer?.data?.model?.orderNumber;
  console.log(orderNumber, 'orderNumber');
  const uploadButton = (
    <div>
      <PlusOutlined type="plus" className="upload-btn-wrapper-edit" />
      <div className="upload-btn">اضافة صور</div>
    </div>
  );
  const [isImageClicked, setIsImageClicked] = useState(false);
  const [fileList, setFileList] = useState([]);
  console.log(fileList, 'fileListArr');
  const imageList = Form.useWatch('ProductImages', form);
  console.log(imageList, 'ProductImages');
  useEffect(() => {
    setFileList(
      imageList?.map((item) => {
        return {
          uid: item?.id,
          name: item?.name,
          status: 'done',
          thumbUrl: item?.imageURL || item?.thumbUrl,
          originFileObj: item?.originFileObj,
          id: item?.id,
        };
      })
    );
  }, [imageList]);
  const { mutate: updateProducts, isLoading: loadingUpdate } = useMutation(
    editProducts,
    {
      onSuccess: (res) => {
        console.log('  res onSuccess :', res);
        if (res) {
          message.success('تم تعديل المنتج بنجاح');
          client.invalidateQueries([queryKeys?.productList]);
          form.resetFields();
          navigate('/products');
        } else {
          message.error('حدث خطأ ما');
        }
      },
      onError: (error) => {
        console.log('error :', error);
        message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
      },
    }
  );
  const { mutate: updateProductCoustomer, isLoading: loadingUpdateCoustomer } =
    useMutation(editProductCoustomer, {
      onSuccess: (res) => {
        console.log('  res onSuccess :', res);
        if (res) {
          message.success('تم تعديل المنتج بنجاح');
          client.invalidateQueries([queryKeys?.productList]);
          form.resetFields();
          navigate('/products');
        } else {
          message.error('حدث خطأ ما');
        }
      },
      onError: (error) => {
        console.log('error :', error);
        message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
      },
    });
  const { mutate: updateFlower, isLoading: loadingUpdateFlower } = useMutation(
    editFlower,
    {
      onSuccess: (res) => {
        console.log('  res onSuccess :', res);
        if (res) {
          message.success('تم تعديل المنتج بنجاح');
          client.invalidateQueries([queryKeys?.flowerList]);
          form.resetFields();
          navigate('/flowers');
        } else {
          message.error('حدث خطأ ما');
        }
      },
      onError: (error) => {
        console.log('error :', error);
        message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
      },
    }
  );
  const onFinish = (values) => {
    console.log('values ', values);
    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('description', values?.description);
    formData.append('price', values?.price);
    formData.append('color', values?.color || undefined);
    formData.append('size', values?.size || undefined);
    values?.ProductImages?.forEach((item) => {
      formData.append('ProductImages', item?.originFileObj || item?.thumbUrl);
    });
    const combinedArray = values?.categoryProduct?.concat(
      values?.categoryGender,
      values?.categoryAge
    );
    combinedArray?.forEach((item, index) => {
      formData.append(`giftCategories[${index}].categoryId`, item?.id || item);
    });
    if (category === 'flower') {
      formData.append('productType', 1);
      formData.append('id', IdEditFlower);
      updateFlower(formData);
      // console.log("formData", values);
    }
    if (category === 'product') {
      formData.append('productType', 0);
      formData.append('id', IdEdit);
      updateProducts(formData);
    } else {
      formData.append('productType', 0);
      formData.append('id', IdEdit);
      formData.append(
        'OrderNumber',
        orderNumber
        // orderCustomer?.data?.model?.orderNumber || ''
      );
      formData.append(
        'orderNumber',
        orderNumber
        // orderCustomer?.data?.model?.orderNumber || ''
      );
      updateProductCoustomer(formData);
    }
  };
  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('حجم الصورة يجب ان يكون اقل من 2 ميجا بايت');
      return false;
    }
    return isLt2M;
  };
  const handleUpload = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const config = {
    rules: [
      {
        // type: 'array',
        required: true,
        message: 'يجب تحديد تصنيف المنتج',
      },
    ],
  };
  const handleRemove = useMutation(
    () => {
      if (category === 'flower') {
        return deleteProduct(IdEditFlower);
      } else {
        return deleteProduct(IdEdit);
      }
    },
    {
      onSuccess: () => {
        message.success('تم الحذف بنجاح');
        if (category === 'flower') {
          client.invalidateQueries([queryKeys.flowerList]);
          navigate('/flowers');
        } else {
          client.invalidateQueries([queryKeys.productList]);
          navigate('/products');
        }
      },
      onError: () => {
        message.error('حدث خطأ ما');
      },
    }
  );
  const confirm = (e) => {
    handleRemove.mutate();
  };
  const cancel = (e) => {
    message.error('لم يتم الحذف');
  };
  if (
    productDetails?.isLoading ||
    flowerDetails?.isLoading ||
    orderCustomer?.isLoading
  ) {
    return <EditProductLoading />;
  }
  const handelRemove = (item) => {
    // console.log("item", item);
    const { id } = item;
    return removeImage.mutate(id);
  };
  return (
    <div className="Products-edit">
      <>
        <div className="products-edit-header flex justify-between align-center w-full h-[56px] text-[#6D29F6]">
          <Button type="link" className="category-add-btn-bold">
            {category === 'flower' ? (
              <Link to="/flowers">باقات الورد</Link>
            ) : (
              <Link to="/products">المنتجات</Link>
            )}
          </Button>
          <div className="category-add-btns">
            <Button
              type="link"
              className="category-add-btn btn-edit-save"
              onClick={() => {
                form.submit();
              }}
            >
              حفظ التعديلات
            </Button>
            <Popconfirm
              title="احذف المنتج ؟"
              description="هل انت متاكد من حذف المنتج ?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="نعم"
              cancelText="لا"
            >
              <Button type="text" className="btn-edit-delete" danger>
                حذف
              </Button>
            </Popconfirm>
          </div>
        </div>
        <div className="products-edit-body">
          <div className="product-add">
            <Form
              name="form_item_path"
              layout="vertical"
              onFinish={onFinish}
              form={form}
              initialValues={{
                ProductImages: giftImages,
                name: name,
                price: price,
                color: color === 'undefined' || color === null ? '' : color,
                size: size === 'undefined' || size === null ? '' : size,
                description: description,
              }}
            >
              <div className="product-add-content">
                <div className="add-images">
                  <Form.Item
                    name="ProductImages"
                    label=""
                    rules={[
                      {
                        required: true,
                        message: 'يجب اضافة صور',
                      },
                    ]}
                  >
                    <Upload
                      name="ProductImages"
                      // listType="picture"
                      listType="picture-card"
                      fileList={fileList}
                      showUploadList={{ showRemoveIcon: true }}
                      // onPreview={() => setIsImageClicked(true)}
                      onChange={({ fileList }) => {
                        console.log('fileList', fileList);
                        setFileList(fileList?.map((file) => file));
                        form.setFieldsValue({
                          ProductImages: fileList.map((file) => file),
                        });
                      }}
                      multiple
                      accept="image/*"
                      beforeUpload={beforeUpload}
                      customRequest={handleUpload}
                      onRemove={handelRemove}
                      // onRemove={(file) => (
                      //   <Popconfirm
                      //     title="هل أنت متأكد من أنك تريد حذف هذه الصورة؟"
                      //     icon={<ExclamationCircleOutlined />}
                      //     onConfirm={() => handleRemove(file)}
                      //     okText="نعم"
                      //     cancelText="لا"
                      //   >
                      //     <DeleteOutlined />
                      //   </Popconfirm>
                      // )}
                    >
                      {uploadButton}
                    </Upload>
                  </Form.Item>
                </div>
                <div className="add-info">
                  <div className="add-info-form">
                    <h2 className="add-title">تفاصيل المنتج</h2>
                    <Form.Item
                      name="name"
                      label="اسم المنتج"
                      rules={[
                        {
                          required: true,
                          message: 'يجب  اضافة اسم المنتج',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="price"
                      label="سعر المنتج"
                      rules={[
                        {
                          required: true,
                          message: 'يجب  اضافة سعر المنتج',
                        },
                      ]}
                    >
                      {/* <Input type="number" suffix="SR"
                  defaultValue={data?.price}
                  /> */}
                      <InputNumber suffix="SR" style={{ width: '95%' }} />
                    </Form.Item>
                    <Form.Item
                      name="color"
                      label={
                        <>
                          <div className="add-label">
                            اللون <span>(اختياري)</span>
                          </div>
                        </>
                      }
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="size"
                      label={
                        <>
                          <div className="add-label">
                            المقاس <span>(اختياري)</span>
                          </div>
                        </>
                      }
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="description"
                      label="وصف المنتج"
                      rules={[
                        {
                          required: true,
                          message: 'يجب  اضافة وصف المنتج',
                        },
                      ]}
                    >
                      <TextArea
                        style={{
                          width: '100%',
                          height: '155px',
                          padding: '10px',
                          resize: 'none',
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="add-info-category">
                    <AddCategory />
                    <Form.Item
                      name="categoryGender"
                      label="الجنس"
                      className="checkbox-list-wrapper"
                      {...config}
                    >
                      <CheckboxCategoryGender
                        setValues={(value) => {
                          console.log(value, 'value-check');
                          form.setFieldsValue({
                            categoryGender: value,
                          });
                        }}
                        valueCheck={giftCategories}
                      />
                    </Form.Item>
                    <Form.Item
                      name="categoryAge"
                      label="العمر"
                      className="checkbox-list-wrapper"
                      {...config}
                    >
                      <CheckboxCategoryAge
                        setValues={(value) => {
                          form.setFieldsValue({
                            categoryAge: value,
                          });
                        }}
                        valueCheck={giftCategories}
                      />
                    </Form.Item>
                    <Form.Item
                      name="categoryProduct"
                      label="فئة المنتج"
                      className="checkbox-list-wrapper"
                      {...config}
                    >
                      <CheckboxCategorys
                        setValues={(value) => {
                          // console.log(value, "value-check");
                          form.setFieldsValue({
                            categoryProduct: value,
                          });
                        }}
                        valueCheck={giftCategories}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Flex justify="flex-end" className="submit-btn-wrapper">
                <Button
                  type="primary"
                  className="submit-btn-edit"
                  htmlType="submit"
                  loading={
                    loadingUpdate ||
                    loadingUpdateFlower ||
                    loadingUpdateCoustomer
                  }
                >
                  حفظ التعديلات
                </Button>
              </Flex>
            </Form>
          </div>
        </div>
      </>
    </div>
  );
};

export default ProductsEdit;
