import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";


export const getEventsList = catchAsync(async ( pageCount) => {
  const { data } = await axiosInstance.get(`/EventTypes?Page=${pageCount}&Size=10`);
  return data;
});


export const deleteEvent = catchAsync(async (Id) => {
    const { data } = await axiosInstance.delete(`/EventTypes/${Id}`);
    return data;
  });


export const addEvents = catchAsync(async (body) => {
  const { data } = await axiosInstance.post('/EventTypes', body);
  return data;
});
export const editEvent = catchAsync(async (body) => {
  const { data } = await axiosInstance.put('/EventTypes', body);
  return data;
});

