import React from 'react'
import { Button, Form, Input, Modal, Select, message } from "antd";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import  { useState } from "react";
import "./AddCategory.scss"
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addCategory } from '../../network/category';
import { queryKeys } from '../../services/react-query/queryKeys';
const AddCategory = () => {
  const client = useQueryClient();
  const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const handleOk = () => {
      setModalVisible(false);
    };
    const handleCancel = () => {
      setModalVisible(false);
      form.resetFields();
      message.info("لم يتم اضافة التصنيف");
    };
    const {mutate:onAddCategory, isLoading } = useMutation(
      addCategory, {
        onSuccess: res => {
          console.log('  res onSuccess :', res);
          if(res){
            message.success("تم اضافة التصنيف بنجاح");
            client.invalidateQueries([queryKeys.categoryList]);
            client.invalidateQueries([queryKeys.categoryGanderList]);
            client.invalidateQueries([queryKeys.categoryAgeList]);
            form.resetFields();
            setModalVisible(false);
          }else{
            message.error("حدث خطأ ما");
          }
          
        },
        onError: error => {
          console.log('error :', error);
          message.error(
            'حدث خطأ ما الرجاء المحاولة مرة اخرى' 
          );
        },
      }
    )
    const onFinish = (value) => {
      console.log("Value Form Add", value);
      const body = {
        nameEN: value.categoryTitle,
        nameAR: value.categoryTitle,
        categoryTypeId: value.categorySelect,
      }
      onAddCategory(body)
    }

  return (
    <>
        <div className="category-add-header flex justify-between items-center	 w-full h-[56px] text-[#6D29F6]">
            <span
                className='category-add-btn-bold'
              >
                تصنيف المنتج
            </span>
            <Button
                type="link"
                className='category-add-btn'
                icon={<PlusOutlined />}
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                اضافة تصنيف
            </Button>
        </div>
        <div className="add-category">
        <Modal
        title="اضافة تصنيف"
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form name="form_item_path_add_category" layout="vertical" onFinish={onFinish}
            className='w-auto align-end justify-end py-6 gap-3 flex flex-col '
            form={form}
        >
          <Form.Item
            name="categorySelect"
            label="فئة التصنيف"
            rules={[
              {
                required: true,
                message: "يجب  اضافة اسم المنتج",
              },
            ]}
          >
            <Select placeholder="اختر الفئة" >
              <Select.Option value="1">الفئة</Select.Option>
              <Select.Option value="2">الجنس</Select.Option>
              <Select.Option value="3">العمر</Select.Option>
            </Select>
          </Form.Item >
          <Form.Item
            name="categoryTitle"
            label="عنوان التصنيف"
            rules={[
              {
                required: true,
                message: "يجب  اضافة اسم المنتج",
              },
            ]}
          >
            <Input placeholder='اكتب عنوان التصنيف' />
          </Form.Item>
          <Form.Item className="flex align-center gap-22 justify-end " >
          <Button type="text" onClick={handleCancel}
          className=" justify-center align-center size-[14px] text-[#8F9BB3]"
          >
          إلغاء
          </Button>
          <Button htmlType="submit" loading={isLoading} type="default" className=" mr-6 h-[37px] w-[125px] justify-center align-center rounded-md gap-3 size-[14px] text-[#8F9BB3]" >
          اضافة
          </Button>
          </Form.Item>
        </Form>
      </Modal>
        </div>
    </>
  )
}

export default AddCategory