import React, { useEffect, useState } from 'react';
import "./CardImageEdit.scss";
import { LiaEditSolid } from "react-icons/lia";
import { Button, Form, Modal, Spin, Upload, message } from 'antd';
import img from "../../../assets/images/Image.jpg";
import Dragger from 'antd/es/upload/Dragger';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { addImage, editImage, getImageDetails } from '../../../network/images';

const CardImageEdit = ({ category,idEdit,handleSelectFile,editItem,setEditItem }) => {
  // console.log(category, "category");
  // console.log(editItem, "editItem");
  // console.log(idEdit, "idEdit");
  const client = useQueryClient();
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  // console.log(fileList, "fileListArr");
  const [isImageClicked, setIsImageClicked] = useState(false);
  const imageList = Form.useWatch("ImageFile", form);
  // console.log(imageList, "imageList");

  useEffect(() => {
    if (category === "edit") {
      handleSelectFile();
      form.setFieldsValue({
        ImageFile: editItem?.imagePath
      })
      setFileList([{
        name: 'image.png',
        status: 'done',
        url: editItem?.imagePath,
        thumbUrl: editItem?.imagePath,
      }])
    }
  }, [category, idEdit, editItem?.imagePath, handleSelectFile, form]);

  function getActionMessage() {
    return category === "edit" ? "تعديل" : "إضافة";
  }
  const uploadInfo = (
    <div className="card-edit-modal">
      <div className="edit-modal-img">
        <img alt="example" src={img} />
      </div>
      <div className="edit-modal-title">
        <h3>
          اختر الملف وقم بارفاقه هنا أو
          <span className="avatar-uploader">رفع الملف</span>
        </h3>
      </div>
      <div className="edit-modal-text">
        <p>حجم الملف لا يزيد عن: 50MB</p>
      </div>
    </div>
  );
  const successMessage = `تم ${getActionMessage()} الصورة بنجاح`;
  const errorMessage = "حدث خطأ ما";
  const editImageQueryKey = [queryKeys.imageList];
  function handleMutationSuccess(res, queryKey) {
    console.log('res onSuccess:', res);
    if (res) {
      message.success(successMessage);
      client.invalidateQueries(queryKey);
      form.resetFields();
      setModalVisible(false);
      setFileList([]);
    } else {
      message.error(errorMessage);
    }
  }

  function handleMutationError(error) {
    console.log('error:', error);
    message.error('حدث خطأ ما. الرجاء المحاولة مرة أخرى.');
  }

  const { mutate: editImageMutation, isLoading: editImageLoading } = useMutation(
    editImage, {
    onSuccess: res => handleMutationSuccess(res, editImageQueryKey),
    onError: handleMutationError,
  });

  const { mutate: addImageMutation, isLoading: addIsLoading } = useMutation(
    addImage,
    {
    onSuccess: res => handleMutationSuccess(res, editImageQueryKey),
    onError: handleMutationError,
  });

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
    setFileList([]);
    if(category === "edit"){
      setEditItem(null);
    }
    message.info(`لم يتم ${getActionMessage()} الصورة`);
  };
  const onFinish = (values) => {
    const formData = new FormData();
    values?.ImageFile?.fileList?.forEach((item) => {
      formData.append("ImageFile", item?.originFileObj);
    });
  
    if (values?.ImageFile?.fileList?.length === 0) {
      message.error("يجب أختيار صورة");
      return;
    }
  
    if (category === "edit") {
      formData.append("id", idEdit);
      editImageMutation(formData);
    } else {
      addImageMutation(formData);
    }
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("حجم الصورة يجب أن يكون أقل من 2 ميجا بايت");
      return false;
    }
    return isLt2M;
  };

  const handleUpload = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const formName = `form_image_path_${category === "edit" ? `edit-${idEdit}` : "add"}`;
  return (
    <>
      <div className="card-image-edit">
        <div
          className="card-image-edit-icon"
          onClick={() => {
            setModalVisible(true);
          }}
        >
          {category === "edit" ? <LiaEditSolid /> : "+ إضافة صورة"}
        </div>
        <Modal
          name={`card-image-edit-modal`}
          title={category === "edit" ? "تعديل صورة" : "إضافة صورة"}
          open={modalVisible}
          onCancel={handleCancel}
          footer={null}
          className="card-image-edit-modal-container"
        >
          <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name={formName}
            initialValues={{
              ImageFile: editItem?.imagePath
            }}
          >
            <Form.Item 
            rules={[{ required: true, message: "يجب  اضافة صورة" }]}
            name="ImageFile"
            >
              <Dragger
                className="card-image-edit-modal"
                name="ImageFile"
                listType="picture"
                fileList={fileList}
                onPreview={() => setIsImageClicked(true)}
                maxCount={1}
                // onChange={handleChange}
                onChange={({ fileList }) => {
                  console.log("fileList", fileList);
                  setFileList(fileList);
                }}
                multiple
                accept="image/*"
                beforeUpload={beforeUpload}
                customRequest={handleUpload}
              >
                {uploadInfo}
              </Dragger>
            </Form.Item>
            <div className="card-edit-modal-btns">
              <Button type="text" onClick={handleCancel} className="card-edit-modal-btn-cancel">
                الغاء
              </Button>
              <Button type="primary" htmlType="submit" className="card-edit-modal-btn"
              loading={editImageLoading || addIsLoading}
              >
                {getActionMessage()}
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default CardImageEdit;
