import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";


export const getImageList = catchAsync(async (pageCount) => {
  const { data } = await axiosInstance.get(`/Slider?Page=${pageCount}&Size=10`);
  return data;
});
export const getImageDetails = catchAsync(async (Id) => {
  const { data } = await axiosInstance.get(`/Slider/${Id}`);
  return data;
});
export const addImage = catchAsync(async (body) => {
  const { data } = await axiosInstance.post('/Slider', body);
  return data;
});
export const editImage = catchAsync(async (body) => {
  const { data } = await axiosInstance.put('/Slider', body);
  return data;
});

export const deleteImage = catchAsync(async (id) => {
    const { data } = await axiosInstance.delete(`/Slider/${id}`);
    return data;
})