import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";


export const getCategorys = catchAsync(async () => {
  const { data } = await axiosInstance.get('/Category?CategoryTypeId=1');
  return data;
});
export const getCategoryGander = catchAsync(async () => {
  const { data } = await axiosInstance.get('/Category?CategoryTypeId=2');
  return data;
});
export const getCategoryAge = catchAsync(async () => {
  const { data } = await axiosInstance.get('/Category?CategoryTypeId=3');
  return data;
});
export const deleteCategory = catchAsync(async (Id) => {
  const { data } = await axiosInstance.delete(`/Category/${Id}`);
  return data;
});

export const addCategory = catchAsync(async (body) => {
  const { data } = await axiosInstance.post('/Category', body);
  return data;
});

