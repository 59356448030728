import React, { useState } from 'react'
import "./CardImage.scss"
import { Card, Skeleton } from 'antd'
import CardImageDelete from '../CardImageDelete/CardImageDelete';
import CardImageEdit from '../CardImageEdit/CardImageEdit';
import { ImagesPageLoading } from '../../Loading/Loading';
const CardImage = ({image,number,loading,id,data}) => {
    // console.log(data, "data");
    // const isLoad = false
    // console.log(image, "image");
    const [idDelete, setIdDelete] = useState(null); 
    const [idEdit, setIdEdit] = useState(null); 
    const [editItem, setEditItem] = useState();
    // console.log(editItem, "editItem");
    const handleSelectFile = () => {
        const editItemSelect  = data?.model?.find((event) => event?.id === idEdit);
        setEditItem(editItemSelect);
      }
    if(loading) {
        return (
            <>
                <ImagesPageLoading/>
            </>
        )
    }
  return (
    <>
        <div className="card-image-container">
            <Card className="card-image-items"
                hoverable
                cover={
                    <div className="card-img">
                        <img alt="صورة" src={image} />
                    </div>
                }
                >
                <div className="card-image-content">
                    <div className="card-image-number">
                        <p>{number + 1}</p>
                    </div>
                    <div className="card-image-utils">
                        <span className="card-image-edit"
                            onClick={() => setIdEdit(id)}
                        >
                            <CardImageEdit category="edit"
                                idEdit={idEdit}
                                handleSelectFile={handleSelectFile}
                                setEditItem={setEditItem}
                                editItem={editItem}
                                />
                         </span>
                        <span className="card-image-delete"
                            onClick={() => setIdDelete(id)}
                        >
                            <CardImageDelete id={id}
                                idDelete={idDelete}
                                setIdDelete={setIdDelete}
                            />
                        </span>
                    </div>
                </div>
            </Card>
        </div>
    </>
  )
}

export default CardImage