import React from 'react'
import { RequestsDetilesInfoLoading } from '../Loading/Loading';
import {LeftOutlined} from '@ant-design/icons';
import RequestsDetailsInfo from '../RequestsDetiles/RequestsDetailsInfo/RequestsDetailsInfo';
import { getAllEventsDetails } from '../../network/allEvents';
import { Link, useParams } from 'react-router-dom';
import { queryKeys } from '../../services/react-query/queryKeys';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  PiCalendarBlank,
  PiHexagon,
  PiCurrencyDollarSimpleBold,
} from 'react-icons/pi';
import { AiOutlineUpload } from 'react-icons/ai';
import { BsFillTagFill } from 'react-icons/bs';
const EventDetails = () => {
    const { IdEvent } = useParams();
    const {
        data: orderDetails,
        isLoading: isLoadingOrders,
      } = useQuery([queryKeys.allEventsDetails, IdEvent], () =>
        getAllEventsDetails(IdEvent)
      );
      if (isLoadingOrders) {
        return (
          <>
            <RequestsDetilesInfoLoading />
          </>
        );
      }
      const {
        id,
        orderNumber,
        deliveryDate,
        eventDate,
        userPhoneNumber,
        creationDate,
        title,
        userName,
      } = orderDetails?.model || {};
      const isPaid = (text) => {
        if (text == 'مدفوع' || text == 'مكتمل') {
          return 'مدفوع';
        } else {
          return 'غير مدفوع';
        }
      };
      const dataInfo = {
        key: id,
        number: orderNumber || 'لا يوجد',
        phone: userPhoneNumber || 'لا يوجد',
        name: userName || 'لا يوجد',
        deliveryDate: dayjs(deliveryDate).format('YYYY-MM-DD') || 'لا يوجد',
        eventDate: dayjs(eventDate).format('YYYY-MM-DD') || 'لا يوجد',
        creationDate: dayjs(creationDate).format('YYYY-MM-DD'),
        payment: 'اقتراح هدية',
        order: isPaid("غير مدفوع") || 'لا يوجد',
        hashtag: 'لا يوجد',
        serves: title  || 'لا يوجد',
      };
  return (
    <>
      <div className="">
      <div className="requests-detiles">
        <div className="requests-detiles-header">
          <div className="header-product">
            <Link className="header-title" to="/all-events">
              الطلبات
            </Link>
          </div>
          <div className="sub-title-page">
            <p>
              <span> تفاصيل الطلب </span> <LeftOutlined />
              <span> الطلبات </span>
            </p>
          </div>
        </div>
          {/* <RequestsDetailsInfo orderDetails={orderDetails || {}} /> */}
          <div className="requests-detiles-info">
      <div className="requests-detiles-info-card">
        <h3 className="requests-detiles-info-card-title">
          طلب رقم:{' '}
          <span>{dataInfo?.number === 'لا يوجد' ? '0' : dataInfo?.number}</span>
        </h3>
        <div className="requests-detiles-info-card-data">
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <PiCalendarBlank className="data-item-icon" /> تاريخ المناسبه{' '}
            </h4>
            <p className="data-item-text">
              {dataInfo?.eventDate}
            </p>
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <AiOutlineUpload className="data-item-icon" /> تاريخ رفع الطلب{' '}
            </h4>
            <p className="data-item-text">{dataInfo?.creationDate}</p>
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <PiHexagon className="data-item-icon" /> اسم المناسبه{' '}
            </h4>
            <p className="data-item-text">{dataInfo?.serves}</p>
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <PiCurrencyDollarSimpleBold className="data-item-icon" /> الدفع{' '}
            </h4>
            <p className="data-item-text">{dataInfo?.order}</p>
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <BsFillTagFill className="data-item-icon" /> الوسم{' '}
            </h4>
            <p className="data-item-text">{dataInfo?.hashtag}</p>
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h3 className="data-item-title">حالة الطلب</h3>
            <p className="data-item-type" style={{ background:"#CCE4F7", color:"#2D8CF0" }}>
            لم يتم تخطيط الهدية
            </p>
          </div>
        </div>
      </div>
      <div className="requests-detiles-info-client">
        <h3 className="requests-detiles-info-client-title">تفاصيل العميل</h3>
        <div className="requests-detiles-info-client-data">
          <div className="requests-detiles-info-client-data-item">
            <h4 className="data-item-title">الاسم:</h4>
            <p className="data-item-text">{dataInfo?.name}</p>
          </div>
          <div className="requests-detiles-info-client-data-item">
            <h4 className="data-item-title">رقم الجوال:</h4>
            <p className="data-item-text"> {dataInfo?.phone} </p>
          </div>
        </div>
      </div>
    </div>
        </div>
      </div>
    </>
  )
}

export default EventDetails