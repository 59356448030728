import {
  Card,
    Flex,
    Form,
    Skeleton, } from 'antd';



export const EditProductLoading = () => {
  return (
    <>
        <div className="product-skeleton">
        <div className="products-edit-header flex justify-between align-center w-full h-[56px]">
              <Skeleton.Button active />
          <div className="category-add-btns flex gap-2">
            <Skeleton.Button active />
            <Skeleton.Button active />
          </div>
      </div>
      <div className="products-edit-body">
      <div className="product-add">
      <Form>
        <div className="product-add-content">
          <div className="add-images">
            <Skeleton.Input active 
            style={{
                height: "270px",
                marginBottom: "10px",
            }} />
            <div className="image-upload-list">
              <div className="image-upload flex gap-2">
                <Skeleton.Image active />
                <Skeleton.Image active />
                <Skeleton.Image active />
              </div>
          </div>
          </div>
          <div className="add-info">
            <div className="add-info-form">
              <h2 className="add-title"><Skeleton.Button active /></h2>
              <Form.Item>
                <Skeleton.Input active />
              </Form.Item>
              <Form.Item
              >
                <Skeleton.Input active />
              </Form.Item>
              <Form.Item
              >
                <Skeleton.Input active />
              </Form.Item>
              <Form.Item
                
              >
                <Skeleton.Input active />
              </Form.Item>
              <Form.Item
               
              >
                <Skeleton.Input active
                  style={{
                    width: "100%",
                    height: "155px",
                    padding: "10px",
                    resize: "none",
                  }}
                />
              </Form.Item>
            </div>
            <div className="add-info-category">
                <Skeleton.Button active />
                  <Form.Item
                  >
                    <div className="flex gap-1">
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    </div>
                  </Form.Item>
                  <Form.Item
                  >
                    <div className="flex gap-1">
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    </div>
                  </Form.Item>
                  <Form.Item
                  >
                    <div className="flex gap-1">
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    </div>
                  </Form.Item>
            </div>
          </div>
        </div>
        <Flex justify="flex-end" style={{ textAlign: "end"}}>
          <Skeleton.Button active />
        </Flex>
      </Form>
    </div>
      </div>
        </div>
    </>
  )
}
export const DetailsProductLoading = () => {
    return (
    <>
        <div className="header-product">
        <div className="header-title">
        <Skeleton.Button active />
        </div>
        <div className="header-path">
          <div className="text-path">
            <Skeleton.Button active/>
          </div>
        </div>
      </div>
        <div className="product-details-card">
            <div className="product-details-imgs">
                <div className="active">
                    <Skeleton.Image active style={{ width: "100% !important" }} />
                </div>
                <div className="details-img flex-nowrap"
                >
                    <Skeleton.Image active  />
                    <Skeleton.Image active  />
                    <Skeleton.Image active  />
                </div>
                </div>
                <div className="product-details">
                  <div className="details-header">
                    <h3 className="details-header-title"><Skeleton.Input active /></h3>
                    <span className="details-price btn-delete"><Skeleton.Input active /></span>
                   </div>
                <div className="details-info">
                    <Skeleton active />
                </div>
                </div>
              </div>
          </>
    )
}

export const DetailsRestaurantLoading = () => {
    return (
        <>
        <div className="header-product">
        <div className="header-title">
        <Skeleton.Button active />
        </div>
        <div className="header-path">
          <div className="text-path">
            <Skeleton.Button active/>
          </div>
        </div>
      </div>
        <div className="product-details-card">
          <div className="product-details-imgs">
            <div className="active">
              <Skeleton.Image active style={{ width: "100% !important" }} />
            </div>
            <div className="details-img" >
              <Skeleton.Image active  />
              <Skeleton.Image active  />
              <Skeleton.Image active  />
            </div>
          </div>
          <div className="product-details">
            <div className="details-header">
              <h3 className="details-header-title"><Skeleton.Input active /></h3>
            </div>
            <div className="details-info">
                <Skeleton active />
            </div>
          </div>
        </div>
        </>
    )
}

export const EditRestaurantLoading = () => {
    return (
        <>
            <div className="product-skeleton">
            <div className="products-edit-header flex justify-between align-center w-full h-[56px] text-[#6D29F6]">
              
                  <Skeleton.Button active />
              <div className="category-add-btns flex gap-2">
                <Skeleton.Button active />
                <Skeleton.Button active />
              </div>
          </div>
          <div className="products-edit-body">
          <div className="product-add">
          <Form>
            <div className="product-add-content">
              <div className="add-images">
                <Skeleton.Input active 
                style={{
                    height: "270px",
                    marginBottom: "10px",
                }} 
                />
                <div className="image-upload-list">
                  <div className="image-upload flex gap-2">
                    <Skeleton.Image active />
                    <Skeleton.Image active />
                    <Skeleton.Image active />
                  </div>
              </div>
              </div>
              <div className="add-info">
                <div className="add-info-form">
                  <h2 className="add-title"><Skeleton.Button active /></h2>
                  <Form.Item>
                    <Skeleton.Input active />
                  </Form.Item>
                  <Form.Item
                  >
                    <Skeleton.Input active />
                  </Form.Item>
                  <Form.Item
                  >
                    <Skeleton.Input active />
                  </Form.Item>
                  <Form.Item
                    
                  >
                    <Skeleton.Input active />
                  </Form.Item>
                  <Form.Item
                   
                  >
                    <Skeleton.Input active
                      style={{
                        width: "100%",
                        height: "155px",
                        padding: "10px",
                        resize: "none",
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <Flex justify="flex-end" style={{ textAlign: "end"}}>
              <Skeleton.Button active />
            </Flex>
          </Form>
        </div>
          </div>
            </div>
        </>
    )
}

export const RequestsDetilesLoading = () => {
  return (
    <>
      <div className="requests-detiles">
      <div className="requests-detiles-shop">
          <div className="shop-card-info">
            <div className="shop-card-header flex align-center justify-between w-fulls">
              <Skeleton.Button active />
              <Skeleton.Button active className=' skeleton-btn-request' />
            </div>
            <div className="shop-card-data">
              <div className="shop-card-image">
                <Skeleton.Image active />
              </div>
                <div className="shop-data-items">
                  <div className="shop-data-item">
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                  </div>
                  <div className="shop-data-item">
                  <Skeleton.Button active />
                    <Skeleton.Button active />
                  </div>
                  <div className="shop-data-item">
                  <Skeleton.Button active />
                    <Skeleton.Button active />
                  </div>
                  <div className="shop-data-item">
                  <Skeleton.Button active />
                    <Skeleton.Button active />
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div className="requests-detiles-shop">
          <div className="shop-card-info">
            <div className="shop-card-header flex align-center justify-between w-fulls">
              <Skeleton.Button active />
              <Skeleton.Button active  className=' skeleton-btn-request' />
            </div>
            <div className="shop-card-data">
              <div className="shop-card-image">
                <Skeleton.Image active />
              </div>
                <div className="shop-data-items">
                  <div className="shop-data-item">
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                  </div>
                  <div className="shop-data-item">
                  <Skeleton.Button active />
                    <Skeleton.Button active />
                  </div>
                  <div className="shop-data-item">
                  <Skeleton.Button active />
                    <Skeleton.Button active />
                  </div>
                  <div className="shop-data-item">
                  <Skeleton.Button active />
                    <Skeleton.Button active />
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>
    </>
  )
} 

export const RequestsDetilesInfoLoading = () => {
  return (
    <>
          <div className="requests-detiles-info">
      <div className="requests-detiles-info-card">
        <h3 className="requests-detiles-info-card-title">
          <Skeleton.Button active />
        </h3>
        <div className="requests-detiles-info-card-data">
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
              <Skeleton.Button active />
            </h4>
            <Skeleton.Button active />
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
            <Skeleton.Button active />
            </h4>
            <Skeleton.Button active />
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
            <Skeleton.Button active />
            </h4>
            <Skeleton.Button active />
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
            <Skeleton.Button active />
            </h4>
            <Skeleton.Button active />
          </div>
          <div className="requests-detiles-info-card-data-item">
            <h4 className="data-item-title">
            <Skeleton.Button active />
            </h4>
            <Skeleton.Button active />
          </div>
          <div className="requests-detiles-info-card-data-item">
            <Skeleton.Button active />
            <Skeleton.Button active />
          </div>
        </div>
      </div>
      <div className="requests-detiles-info-client">
          <h3 className="requests-detiles-info-client-title">
          <Skeleton.Button active />
          </h3>
          <div className="requests-detiles-info-client-data">
            <div className="requests-detiles-info-client-data-item">
              <h4 className='data-item-title'>
              <Skeleton.Button active />
              </h4>
              <Skeleton.Button active />
            </div>
            <div className="requests-detiles-info-client-data-item">
              <h4 className='data-item-title'>
              <Skeleton.Button active />
              </h4>
              <Skeleton.Button active />
            </div>
          </div>
      </div>
    </div>
    </>
  )
}

export const ImagesPageLoading = () => {
  return (
    <>
    <div className="card-image-container">
        <Card className="card-image-items"
          hoverable
          cover={
            <Skeleton.Image active style={{ width: "100% !important" }} /> 
          }
          >
          <div className="card-image-content">
            <div className="card-image-number">
                <Skeleton.Button active />
            </div>
            <div className="card-image-utils">
                <div className="card-image-edit">
                    <Skeleton.Button active />
                </div>
                <div className="card-image-delete btn-delete">
                    <Skeleton.Button active />
                </div>
            </div>
          </div>
        </Card>
    </div>
</>
  )
}

export const DashboardLoading = () => {
  return (
    <div className="dashboard">
      <div className="dashboard-title">
          <h1 className="dashboard-title-text"><Skeleton.Input active /></h1>
        </div>
      <div className="dashboard-card">
        {[1,2,3,4,5,6].map(item => (
            <Card
            key={item}
            style={{
              width: 192,
              height:138,
              borderColor:"rgba(143, 155, 179, 0.50)",
              borderRadius: 12,
             }}
            >
            <div className="dashboard-card-item">
              {/* <div className="card-title-dashboard-container"> */}
                <h3 className="card-title-dashboard">
                  <Skeleton.Input active size="small" />
                </h3>
              {/* </div> */}
              <div className="card-content">
                <h2 className="card-content-dashboard"><Skeleton.Button active /></h2>
              </div>
            </div>
           </Card>
        ))}
      </div>
    </div>
  )
}