import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";


export const getRestaurantsList = catchAsync(async (pageCount) => {
  const { data } = await axiosInstance.get(`/Restaurant?Page${pageCount}&Size=10`);
  return data;
});
export const getRestaurantDetails = catchAsync(async (Id) => {
  const { data } = await axiosInstance.get(`/Restaurant/${Id}`);
  return data;
});

export const addRestaurant = catchAsync(async (body) => {
  const { data } = await axiosInstance.post('/Restaurant', body);
  return data;
});

export const deleteRestaurant = catchAsync(async (Id) => {
  const { data } = await axiosInstance.delete(`/Restaurant/${Id}`);
  return data;
});

export const updateRestaurant = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/Restaurant`, body);
  return data;
});