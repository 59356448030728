import {useState} from 'react'
import "./RestaurantDetails.scss"
import SectionHeader from '../../SectionHeader/SectionHeader'
import {EditOutlined} from '@ant-design/icons'
import { useParams } from 'react-router'
import { getRestaurantDetails } from '../../../network/restaurants'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from '../../../services/react-query/queryKeys'
import { Skeleton } from 'antd'
import { DetailsRestaurantLoading } from '../../Loading/Loading'
const RestaurantDetails = () => {
  const [imgActive, setImgActive] = useState(0);
  const { Id } = useParams();
  // console.log(Id);
  const { data, isLoading } = useQuery([queryKeys.restaurantList, Id], () => getRestaurantDetails(Id));

  const { name, description, websiteURL, city, classification, giftImages } = data?.model || {};
  if(isLoading){
    return (
      <>
      <div className="restaurant-details">
        <DetailsRestaurantLoading/>
      </div>
      </>
    )
  }
  return (
    <>
      <div className="restaurant-details">
        <SectionHeader
          title=" المطاعم"
          titlePath="/restaurant"
          text=" تعديل "
          icon={<EditOutlined />}
          textPath={`/restaurant/edit/${Id}`}
          subNextTitle="تفاصيل المطعم"
          subTitle="المطاعم"
        />
          <div className="product-details-card">
          <div className="product-details-imgs">
            <div className="active">
              <img src={giftImages?.[imgActive]?.imageURL} alt={name} />
            </div>
            <div className="details-img flex-wrap">
                {giftImages?.map((img, index) => (
                   <div  key={index}
                      onClick={() => setImgActive(index)}
                      className={index === imgActive ? "img-active image-upload" : "image-upload"}
                   >
                    <img src={img?.imageURL} alt={name} className="ant-upload-list-item-image"
                    />
                </div>
                ))}
              </div>
          </div>
          <div className="product-details">
            <div className="details-header">
              <h3 className="details-header-title">{name}</h3>
            </div>
            <div className="details-info">
              <div className="card-info">
                <p className="card-info-title"> التنصيف: </p>
                <span className="card-info-desc"> {classification} </span>
              </div>
              <div className="card-info">
                <p className="card-info-title"> المدينة: </p>
                <span className="card-info-desc"> {city} </span>
              </div>
              <div className="card-info">
                <p className="card-info-title"> رابط موقع المطعم: </p>
                <a style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer" href={websiteURL}>
                  قوقل مابز
                </a>
              </div>
              <div className="card-info-line">
                <p className="card-info-title"> الوصف: </p>
                <span className="card-info-desc"> {description} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RestaurantDetails