import "./RestaurantAdd.scss"
import { Button, Flex, Form, Input, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from 'antd/es/input/TextArea';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { addRestaurant } from "../../../network/restaurants";
import { useState } from "react";



const RestaurantAdd = () => {
  const navigate = useNavigate();
  const [isImageClicked, setIsImageClicked] = useState(false);
  const [fileList, setFileList] = useState([]);
  console.log(fileList, "fileList");
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  // const client = useQueryClient();
  const { mutate: onAddRestaurant, isLoading } = useMutation(addRestaurant, {
    onSuccess: res => {
      console.log('  res onSuccess :', res);
      if(res){
        message.success("تم اضافة المنتج بنجاح");
        form.resetFields();
        navigate('/restaurant');
      }else{
        message.error("حدث خطأ ما");
      }
      
    },
    onError: error => {
      console.log('error :', error);
      message.error(
        'Please enter a correct email and password. Note that both fields may be case-sensitive.'
      );
    },
  });
  const onFinish = (values) => {
    console.log("Value Form Add Restaurant", values);
    let formData = new FormData();
    formData.append("name", values?.name);
    formData.append("city", values?.city);
    formData.append("description", values?.description);
    formData.append("websiteURL", values?.websiteURL);
    formData.append("Classification", values?.Classification);
    values?.RestaurantImages?.fileList?.forEach((item) => {
      formData.append("RestaurantImages", item?.originFileObj);
    })
    onAddRestaurant(formData);
  };
    const uploadButton = (
        <div>
          <PlusOutlined type="plus" className='upload-btn-wrapper-edit' />
          <div className="upload-btn">اضافة صور</div>
        </div>
    );
  const [form] = Form.useForm();
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
      const beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error("حجم الصورة يجب ان يكون اقل من 2 ميجا بايت");
          return false;
        }
        return isLt2M;
      };
    
      const handleUpload = async ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };
  return (
    <>
        <div className="restaurant-add">
            <div className="product-add">
          <div className="header-product mb-5 ">
          <div
            onClick={() => {
              navigate("/restaurant");
            }}
          >
            <span className="header-title">
             المطاعم
            </span>
          </div>
          <div
          className="header-path"
            // onClick={() => {
            //   form.submit();
            // }}
          >
            <span className="text-path">
            اضافة مطعم
            </span>
          </div>
        </div>
            <Form 
            name="form_item_path"
            layout="vertical"
            onFinishFailed={onFinishFailed}
            onFinish={onFinish}
            form={form}
            >
                <div className="product-add-content">
                <div className="add-images">
                <Form.Item
                  name="RestaurantImages"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "يجب اضافة صور",
                    },
                  ]}
                >
                  <Upload
                  // listType="picture"
                  listType="picture-card"
                  fileList={fileList}
                  // onPreview={() => setIsImageClicked(true)}  
                  onChange={handleChange}
                  multiple
                  accept="image/*"
                  beforeUpload={beforeUpload}
                  customRequest={handleUpload}
                >
                  {uploadButton}
                </Upload>
                </Form.Item>
                
              </div>
                  <div className="add-info">
                    <div className="add-info-form">
                      <h2 className="add-title">تفاصيل المطعم</h2>
                      <Form.Item name="name" label="اسم المطعم"
                      rules={[
                        {
                          required: true,
                          message: "يجب  اضافة اسم المطعم",
                        }
                      ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name="Classification" label="التصنيف"
                      rules={[
                        {
                          required: true,
                          message: "  يجب  اضافة التصنيف المطعم",
                        }
                      ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name="city" label="المدينة"
                      rules={[
                        {
                          required: true,
                          message: "  يجب  اضافة المدينة",
                        }
                      ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name="websiteURL" label="رابط الموقع"
                      rules={[
                        {
                          required: true,
                          message: "يجب  اضافة رابط الموقع",
                        }
                      ]}
                      >
                        <Input type="url" />
                      </Form.Item>
                      <Form.Item name="description" label="وصف المطعم"
                      rules={[
                        {
                          required: true,
                          message: "يجب  اضافة وصف المطعم",
                        }
                      ]}
                      >
                        <TextArea 
                         style={{
                            width: "100%",
                            height: "155px",
                            padding: "10px",
                            resize: 'none',
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <Flex justify="flex-end" style={{ marginTop: "24px" }}>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    اضافة
                  </Button>
                </Flex>
            </Form>
            </div>
        </div>
    </>
  )
}

export default RestaurantAdd