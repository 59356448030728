import { Button, Calendar, Form, Input, Modal, Upload, Popover, message } from "antd";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import  { useState, useEffect,useCallback } from "react";
import "./AddEvent.scss";
import BtnEmoji from '../../shared/BtnEmoji';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addEvents, editEvent } from "../../../network/eventList";
import { queryKeys } from "../../../services/react-query/queryKeys";
import RenderHeaderCalendar from "../../Calendar/RenderHeader";
const AddEvent = ({ openModal, setOpenModal, setEditItem, editItem }) => {
  console.log(editItem, "editItem");
  const client = useQueryClient();
  const [valueDate, setValueDate] = useState();
  const [input, setInput] = useState('');
  const [form] = Form.useForm();


  useEffect(() => {
    if (editItem) {
      // setValueDate(editItem?.eventDate);
      form.setFieldsValue({
        EmojiCode: editItem?.emotion,
        Title: editItem?.name,
        eventDate: editItem?.eventDate,
        titleTag: editItem?.tagName,
      });
    } else {
      form.resetFields();
    }
  }, [editItem, form]);
  

  const handleOk = () => {
    setOpenModal(false);
    setEditItem(null);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setEditItem(null);
    form.resetFields();
    if(editItem) {
      message.info("تم الغاء التعديل المناسبة");
    } else {
      message.info("تم الغاء اضافة المناسبة");
    }
  };
  const onAddEvents = useMutation(
    addEvents,
    {
    onSuccess: res => {
      console.log('  res onSuccess :', res);
      if(res){
        client.invalidateQueries([queryKeys.eventList]);
        message.success("تم اضافة المناسبة بنجاح");
        form.resetFields();
        setOpenModal(false);
        setEditItem(null);
      }else{
        message.error("حدث خطأ ما");
      }
      
    },
    
    onError: error => {
      console.log('error :', error);
      message.error(
        'حدث خطأ ما الرجاء المحاولة مرة اخرى' 
      );
    },
  });
  const  onEditEvents = useMutation( 
    editEvent, {
    onSuccess: res => {
      console.log('  res onSuccess :', res);
      if(res){
        message.success("تم تعديل المناسبة بنجاح");
        client.invalidateQueries([queryKeys.eventList]);
        form.resetFields();
        setOpenModal(false);
        setEditItem(null);
      }else{
        console.error('An error occurred:', res);
        message.error('حدث خطأ غير متوقع. يرجى المحاولة مرة أخرى.');
      }
    },
    onError: error => {
      console.error('An error occurred:', error);
      message.error(
        'حدث خطأ أثناء معالجة الطلب. يرجى المحاولة مرة أخرى.' 
      );
    },
  }
  );

  const onFinish = (value) => {
    console.log("Value Form Add", value);
    const eventData = {
      emotion: value.EmojiCode,
      // eventDate: valueDate,
      eventDate: value.eventDate || valueDate,
      nameAR: value.Title,
      nameEN: value.Title,
      tagName: value.titleTag,
    };

    if (editItem) {
      eventData.id = editItem.id;
      onEditEvents.mutate(eventData);
    } else {
      onAddEvents.mutate(eventData);
    }
  };

  const renderEmojiComponent = () => (
    <BtnEmoji
      onEmojiSelect={(emoji) => {
        form?.setFieldValue("EmojiCode", emoji);
        setInput((prev) => prev + emoji);
      }}
      emojiEdit={editItem}
    />
  );

  return (
    <>
        <div className="add-event flex justify-between align-center w-full h-[56px] text-[#6D29F6]">
            <Button
                type="link"
                className='category-add-btn-bold'
                onClick={() => {
                  setEditItem(null);
                }}
              >
                اضافة مناسبة
            </Button>
            <Button
                type="link"
                className='category-add-btn'
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditItem(null);
                  setOpenModal(true);
                }}
              >
              اضافة مناسبة
            </Button>
          </div>
        <div className="add-category">
        <Modal
        title={editItem ? "تعديل مناسبة" : "اضافة مناسبة"}
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        
      >
        <Form name="form_item_path_add" layout="vertical" form={form} onFinish={onFinish}
            className='w-auto align-end justify-end py-6 gap-3 flex flex-col add-event-form '
        >
          <Form.Item
            name="EmojiCode"
            label="الرمز التعبيري"
            className="emoji-upload"
            rules={[
              {
                required: true,
                message: "يجب  اضافة الرمز التعبيري",
              },
            ]}
          >
            {renderEmojiComponent()}
        </Form.Item>
          <Form.Item
            name="Title"
            label="عنوان المناسبة"
            rules={[
              {
                required: true,
                message: "يجب  اضافة عنوان المناسبة",
              },
            ]}
          >
            <Input placeholder='اضافة عنوان المناسبة' />
          </Form.Item >
          <Form.Item
            name="eventDate"
            label="تاريخ المناسبة"
            rules={[
              {
                required: true,
                message: "يجب  اضافة تاريخ المناسبة",
              }
            ]}
          >
            <RenderHeaderCalendar
                setValue={value => {
                  form?.setFieldValue( "eventDate", value);
                  setValueDate(value);
                }}
                editItem={editItem}
            />
          </Form.Item >
          <Form.Item
            name="titleTag"
            label="عنوان الوسم"
            rules={[
              {
                required: true,
                message: "يجب  اضافة عنوان الوسم",
              },
            ]}
          >
            <Input placeholder='اضافة عنوان الوسم' />
          </Form.Item >
          <Form.Item className="flex align-center gap-22 justify-end " >
          <Button type="text" onClick={handleCancel}
          className=" justify-center align-center size-[14px] text-[#8F9BB3]"
          >
          إلغاء
          </Button>
          <Button htmlType="submit" type="default" loading={onAddEvents.isLoading || onEditEvents.isLoading} className=" mr-6 h-[37px] w-[125px] justify-center align-center rounded-md gap-3 size-[14px] text-[#8F9BB3]" >
            {editItem ? "تعديل" : "اضافة"}
          </Button>
          </Form.Item>
        </Form>
      </Modal>
        </div>
    </>
  )
};

export default AddEvent;
