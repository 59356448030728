import  { useEffect,useState } from 'react';
import { Calendar, Col, Row, Select, Typography } from "antd";
import "./RenderHeader.scss";
const RenderHeaderCalendar = ({ setValue, value,editItem}) => {
  // console.log(value, "value-date-header-RenderHeaderCalendar");
  // console.log(valueEdit, "value-date-header-RenderHeaderCalendar-valueEdit");
  useEffect(() => {
    if (editItem) {
      setValue(new Date(editItem.eventDate));
    } else {
      setValue(new Date());
    }
  }, [editItem]);
  
  const values = new Date(value ? value : new Date());
  // console.log(values, "value-date-header");
  const dayNumber = values.getDate();
  const monthNumber = values.getMonth();
  const yearNumber = values.getFullYear();
  console.log(dayNumber, "dayNumber");
  console.log(monthNumber + 1, "monthNumber");
  console.log(yearNumber, "yearNumber");
  const [years, setYears] = useState(new Date().getFullYear());
  const RenderHeader = ({ value, onChange }) => {
    const mons = [
      { label: "يناير", value: 1 },
      { label: "فبراير", value: 2 },
      { label: "مارس", value: 3 },
      { label: "ابريل", value: 4 },
      { label: "مايو", value: 5 },
      { label: "يونيو", value: 6 },
      { label: "يوليو", value: 7 },
      { label: "اغسطس", value: 8 },
      { label: "سبتمبر", value: 9 },
      { label: "اكتوبر", value: 10 },
      { label: "نوفمبر", value: 11 },
      { label: "ديسمبر", value: 12 },
    ];

    const year = years;
    const setYear = year - 5;
    const yearOptions = Array.from({ length: 30 }, (_, i) => ({
      key: setYear + i,
      value: setYear + i,
      className: "year-item",
      children: setYear + i,
    }
    ));
    const day = Array.from({ length: 31 }, (_, i) => i + 1);
    const handleDayChange = (newDay) => {
      const newValue = value.clone().set("date", newDay).set("month", monthNumber).set("year", yearNumber);
      setValue(newValue);
      return onChange(newValue);
    }
    const handleMonthChange = (newMonth) => {
        const newValue = value.clone().set("date", dayNumber).set("month", newMonth).set("year", yearNumber);
        setValue(newValue);
        return onChange(newValue);
    };
    const handleYearChange = (newYear) => {
      setYears(newYear);
      const newValue = value.clone().set("date", dayNumber).set("month", monthNumber).set("year", newYear);
      setValue(newValue);
      return onChange(newValue);
    };
    return (
      <>
        <Row gutter={[35, 0]}>
          <Col>
            <Typography.Title level={5}>اليوم </Typography.Title>
            <Select
              bordered={false}
              size="small"
              placeholder="اليوم"
              className="my-date-select"
              onChange={handleDayChange}
              defaultValue ={values ? dayNumber :  null }
              value={values ? dayNumber :  null }
            >
              {day.map((i) => (
                <Select.Option 
                  key={i} 
                  value={i} 
                  className="month-item" 
                  children={i} 
                />
              ))}
            </Select>
          </Col>
          <Col>
            <Typography.Title level={5}> الشهر</Typography.Title>
            <Select
              bordered={false}
              size="small"
              placeholder="الشهر"
              onChange={handleMonthChange}
              defaultValue={values ? monthNumber :  null }
              value={values ? monthNumber :  null }
            >
              {mons.map((i) => (
                <Select.Option
                  key={i.value} 
                  value={i.value - 1} 
                  className="month-item" 
                  children={i.label} 
                />
              ))}
            </Select>
          </Col>
          <Col>
            <Typography.Title level={5}> السنة </Typography.Title>
            <Select
              bordered={false}
              size="small"
              className="my-year-select"
              placeholder="السنة"
              onChange={handleYearChange}
              defaultValue={values ? yearNumber :  null }
              value={values ? yearNumber :  null }
            >
              {yearOptions.map((option) => (
                <Select.Option {...option} />
              ))}
            </Select>
          </Col>
        </Row>
      </>
    );
  };

  const onPanelChange = (value) => {
    setValue(value);
  };

  return (
    <Calendar
      fullscreen={true}
      headerRender={(props) => <RenderHeader {...props} />}
      onPanelChange={onPanelChange}
    />
  );
};

export default RenderHeaderCalendar;


