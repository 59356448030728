import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";


export const getAllEvents = catchAsync(async ({pageCount}) => {
  // const { data } = await axiosInstance.get(`/Event?IsEN=true&IsAPP=false&?Page=${pageCount }&Size=10`);
  const { data } = await axiosInstance.get(`/Event/GetAllEvents?IsEN=true&IsAPP=true&Page=${pageCount}`);
  return data;
});


export const getAllEventsDetails = catchAsync(async (IdRequest) => {
  // const { data } = await axiosInstance.get(`/Order/${IdRequest}`);
  const { data } = await axiosInstance.get(`/Event/${IdRequest}?IsEN=true&IsApp=false`);
  return data;
});
